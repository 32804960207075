import {
  ElementPropertyInputType,
  ElementPropertyName,
  IFactorySelectProperty,
} from '../../models/element_property.interface';
import { formatSbefCodes } from './categories-properties.helpers';

export const sbefCodeProperty: IFactorySelectProperty = {
  type: ElementPropertyInputType.Select,
  name: ElementPropertyName.SbefCode,
  options: ElementPropertyName.SbefCode,
  inheritFallback: true,
};

export const SBEF_CODES = Object.freeze(['none', ...formatSbefCodes()]);
