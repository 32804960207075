import { v4 } from 'uuid';
import {
  IElement,
  IElementID,
  OneOfElements,
  OneOfParentElements,
  IElementVersion,
  OneOfListElements,
  IBuildingVersion,
  ElementKind,
} from '../models/project.interface';
import {
  OneOfSearchableElements,
  findElementAndParent,
  getChildElements,
  getElementById,
  getParentElement,
  isElement,
} from './recursive_element_helpers';
import {
  addElementsToParent,
  OneOfFactoryElements,
} from './element_factory_helpers';
import { EMPTY_ARRAY } from './array_helpers';
import { getId } from './object_helpers';
import { ItemOrItemId, SemiPartial } from '../models/type_helpers.interface';
import {
  generateElementNameAndFallbackName,
  updateChildElements,
  updateElements,
} from './project_helpers';
import { getProposalsWithElementSelected } from './proposal.helpers';
import { requiredKind } from './element_helpers';
import { required } from './function_helpers';
import {
  applyRegenerateIdsOptions,
  shouldRegenerateIds,
  RegenerateIds,
} from './id.helpers';

/**
 * Input types for getElementVersionId
 */
export type ElementOrVersionId =
  | OneOfSearchableElements
  | OneOfListElements
  | IElement['versionId']
  | undefined;

/**
 * Get element version id from an element.
 * If the input is already an element version id, it will return the same id.
 * @param elementOrVersionId
 * @returns
 */
export const getElementVersionId = (
  elementOrVersionId?: ElementOrVersionId | OneOfFactoryElements,
): IElement['versionId'] | undefined => {
  if (typeof elementOrVersionId === 'string' || !elementOrVersionId) {
    return elementOrVersionId;
  }
  return 'versionId' in elementOrVersionId
    ? elementOrVersionId.versionId
    : undefined;
};

/**
 * If an element is an element version and thus have a versionId
 * @param element
 * @returns
 */
export const isElementVersionElement = (
  element?: OneOfSearchableElements | OneOfListElements,
): element is IElementVersion => getElementVersionId(element) !== undefined;

/**
 * Test if an is an element version but an inactive one
 * @param element
 * @returns
 */
export const isInactiveElementVersion = (element: OneOfListElements): boolean =>
  isElementVersionElement(element) && !element.isActiveVersion;

/**
 * Check if an element is the active version. If only one version exists it will be considered active.
 * @param element
 * @returns
 */
export const isActiveElementVersion = (element: OneOfListElements): boolean =>
  !isElementVersionElement(element) || !!element.isActiveVersion;
/**
 * Return all versions of an element. If no versions the element itself will be returned.
 * @param searchIn
 * @param element
 * @returns
 */
export const getElementVersions = (
  searchIn: OneOfSearchableElements,
  element: OneOfListElements,
) => {
  if (!isElement(element)) {
    return [];
  }
  const versions = getElementVersionsById(searchIn, element);

  return versions.length === 0 ? [element] : versions;
};

/**
 * Get all element versions related to a specific element.
 * @param searchIn
 * @param element
 */
export const getElementVersionsById = (
  searchIn: OneOfSearchableElements,
  elementOrVersionId: ElementOrVersionId,
): IElementVersion[] => {
  const versionId = getElementVersionId(elementOrVersionId);

  // No version exists (save cpu by aborting)
  if (!versionId) {
    return EMPTY_ARRAY as IElementVersion[];
  }

  // Find the parent of the element
  const { parent } = findElementAndParent(
    searchIn,
    (e) => getElementVersionId(e) === versionId,
  );

  // Return all child elements of the parent that are elements and have the same id as the versionId
  return getChildElements(parent)
    .filter(isElementVersionElement)
    .filter((e) => getElementVersionId(e) === versionId);
};

export const addElementVersionId = <R extends OneOfParentElements>(
  parent: R,
  originalElementOrId: ItemOrItemId<IElement>,
  versionId?: IElement['versionId'],
): R =>
  updateElements<IElement, R>(parent, {
    id: getId(originalElementOrId),
    versionId: versionId ?? v4(),
  });

/**
 * Create a new version of an element and add it to the parent
 * @param parent
 * @param original
 * @returns
 */
export const addElementVersion = <R extends OneOfParentElements>(
  root: R,
  originalElementOrId: ItemOrItemId<IElement>,
): R => {
  // Use id to find the element to make more flexible and not sensitive to object equality
  const id = getId(originalElementOrId);
  const elementAndParent = findElementAndParent(root, id);
  const original = requiredKind(elementAndParent.element, ElementKind.Element);
  const versionId = getElementVersionId(original) || v4();
  let parent = required(elementAndParent.parent);

  if (!isElement(original) || !parent) {
    throw new Error('Element not found');
  }

  // If original doesn't have a versionId, we need to give it one
  if (!getElementVersionId(original)) {
    parent = addElementVersionId(parent, original, versionId);
  }

  parent = addElementsToParent(
    parent,
    {
      ...original,
      ...generateElementNameAndFallbackName(parent, original),
      versionId,
    },
    true,
  );

  const newElementVersion = getAddedElementVersion(parent, id, false);

  // Make sure element versionId is correct
  parent = addElementVersionId(parent, newElementVersion, versionId);

  if (
    !newElementVersion ||
    getElementVersionsById(parent, versionId).length < 2
  ) {
    throw new Error('There must be at least two versions of an element');
  }

  // Apply changes to searchIn element
  return updateElements(root, parent);
};

/**
 * Cleanup obsolete version ids and set the first version as active if no version is active
 * @param parent
 * @returns
 */
export const cleanupElementVersions = <T extends OneOfParentElements>(
  version: IBuildingVersion,
  parent: T,
): T => {
  const versionIds = getElementVersionIds(getChildElements(parent));

  for (const versionId of versionIds) {
    const elementVersions = getElementVersionsById(parent, versionId);
    const first = elementVersions[0];
    const proposals = version.proposals ?? [];

    const selectedByProposals = first
      ? getProposalsWithElementSelected(proposals, first)
      : [];
    const isSelectedByAllProposals =
      !proposals.length || selectedByProposals.length === proposals.length;

    // Only one version and selected by all proposals => remove versionId
    if (elementVersions.length === 1 && isSelectedByAllProposals) {
      parent = updateChildElements(parent, {
        ...first,
        versionId: undefined,
        isActiveVersion: undefined,
        versionName: undefined,
      } as IElement);
    }

    // No active version selected => select the first
    if (first && !elementVersions.some(isActiveElementVersion)) {
      parent = setActiveElementVersion(parent, first);
    }
  }
  return parent;
};

/**
 * Set an element as the active version
 * @param parent
 * @param element
 * @returns
 */
export const setActiveElementVersion = <R extends OneOfParentElements>(
  parent: R,
  elementOrId: ItemOrItemId<OneOfElements>,
): R => {
  const elementId = getId(elementOrId);
  const element = getElementById(parent, elementId);

  // Not a version element
  if (!isElementVersionElement(element)) {
    return parent;
  }

  const versions = getElementVersionsById(parent, element);

  const changes = versions.map((e) => {
    const isActiveVersion = e.id === elementId;

    // isActiveVersion can be undefined or false when inactive.
    // So don't trigger changes when going from undefined to false
    if (isActiveElementVersion(e) !== isActiveVersion) {
      return {
        id: e.id,
        isActiveVersion,
      };
    }
  });

  // Only change parent if elements have changed
  return updateElements(parent, ...changes);
};

export const deativateElementVersion = <R extends OneOfSearchableElements>(
  root: R,
  element: IElement,
): R => {
  const update: SemiPartial<IElement, 'id'> = {
    id: element.id,
    isActiveVersion: false,
  };
  return updateElements(root, update);
};

/**
 * Generate new version ids for all elements in array.
 * Needed when copying elements to a new version, creating elements from a recipes etc
 * @param elements
 * @param regenerateIds If true it will regerate id for all elements even if they already exist
 * @returns
 */
export const generateNewElementVersionIds = <T extends OneOfElements>(
  elements: T[],
  regenerateIds?: RegenerateIds,
): T[] => {
  const shouldRegenerate = shouldRegenerateIds(
    applyRegenerateIdsOptions(regenerateIds, { isVersionId: true }),
  );
  if (!shouldRegenerate) {
    return elements;
  }
  const idMap = new Map<IElementID, IElementID>();

  const updatedElements = elements.map((element) => {
    const versionId = getElementVersionId(element);

    if (!versionId) {
      return element;
    }

    // Use existing id or generate a new one
    const newVersionId = idMap.get(versionId) ?? v4();
    idMap.set(versionId, newVersionId);

    return {
      ...element,
      versionId: newVersionId,
    } as T;
  });

  // Use the updated elements if any version id was changed
  return idMap.size > 0 ? updatedElements : elements;
};

/**
 * Get all unique versions ids in an array of elements
 * @param elements
 * @returns
 */
export const getElementVersionIds = (
  elements: OneOfElements[],
): NonNullable<IElement['versionId']>[] => {
  const versionIds = new Set<NonNullable<IElement['versionId']>>();
  elements.forEach((e) => {
    const versionId = getElementVersionId(e);
    if (versionId) {
      versionIds.add(versionId);
    }
  });
  return Array.from(versionIds);
};

/**
 * Get added element version.
 * Since it's added by cloning an element and adding it after the original
 * the new element will be the next element after the original
 * @param searchIn
 * @param originalId
 * @param validate If true it will validate that the new element belongs to the same version as the original
 * @returns
 */
export const getAddedElementVersion = (
  searchIn: OneOfSearchableElements,
  originalId: IElement['id'],
  validate = true,
): IElementVersion => {
  const parent = required(getParentElement(searchIn, originalId));
  const children = getChildElements(parent);
  const originalIndex = children.findIndex((e) => e.id === originalId);
  const original = children[originalIndex];
  const newElement = children[originalIndex + 1];
  const versionId = required(getElementVersionId(original));

  if (validate && getElementVersionId(newElement) !== versionId) {
    throw new Error('New element does not belong to the same version');
  }

  // New element is always after the original
  return newElement as IElementVersion;
};
