import { isObject, omit } from 'lodash';
import {
  calculatedUnits,
  ConversionFactors,
  Results,
  QuantityUnit,
  quantityUnits,
  EmissionCostLabel,
  emptyConversionFactors,
} from '../models/unit.interface';
import { mathJS } from './mathjs';
import { multiplyProperties } from './math_helpers';
import { getKeys, omitUndefined } from './object_helpers';
import { isCO2eUnit, isQuantityUnit } from './unit_helpers';

type ConversionUnits =
  | 'mm'
  | 'cm'
  | 'dm'
  | 'm'
  | 'km'
  | 'inch'
  | 'ft'
  | 'yd'
  | 'mi'
  | 'nmi'
  | 'm2'
  | 'dm2'
  | 'km2'
  | 'cm2'
  | 'g'
  | 'hg'
  | 'kg'
  | 'ton'
  | 'kN'
  | 'N';

const G = 9.81;

if (
  typeof mathJS.unit !== 'function' ||
  typeof mathJS.createUnit !== 'function'
) {
  throw new Error('mathjs dependencies are not installed');
}

mathJS.createUnit('N', `${1 / G} kg`, { override: true });
mathJS.createUnit('kN', `1000 N`);

/**
 * Add calculated conversion factors like 'mm'.
 * @param conversionFactors
 * @param elementUnit
 * @returns
 */
export const applyCalculatedConversionFactors = <
  T extends ConversionFactors | Results = ConversionFactors,
>(
  conversionFactors: T,
): T => {
  const { 'm³': m3, m, MJ, l, kWh } = conversionFactors;

  const calculatedFactors: Partial<Record<QuantityUnit, number>> = {};

  // Volume
  if (typeof m3 === 'number') {
    calculatedFactors['l'] = l || m3 * 1000;
  } else if (typeof l === 'number') {
    // Some products are in liter
    calculatedFactors['m³'] = l / 1000;
  }

  // Length
  if (typeof m === 'number') {
    calculatedFactors['mm'] = m * 1000;
  }

  // Energy
  if (typeof MJ === 'number') {
    calculatedFactors['kWh'] = MJ / 3.6;
  } else if (typeof kWh === 'number') {
    calculatedFactors['MJ'] = kWh * 3.6;
  }

  return { ...conversionFactors, ...calculatedFactors };
};

export const stripCalculatedConversionFactors = (
  conversionFactors: ConversionFactors,
): ConversionFactors => {
  return omit(conversionFactors, calculatedUnits);
};

/**
 * Convert the count to another unit maintaining the same ratio.
 * Note that ConversionFactors now has been migrated to expected format (2022-08-30)
 * Sample: {kg: 1, m³: 0.00142857}
 */
export const convertCount = (
  count: number,
  conversionFactors: ConversionFactors,
  from: QuantityUnit,
  to: QuantityUnit,
): number => {
  conversionFactors = applyCalculatedConversionFactors(conversionFactors);

  if (from === to) {
    return count;
  }
  const fromFactor = conversionFactors[from];
  const toFactor = conversionFactors[to];

  if (
    typeof fromFactor !== 'number' ||
    typeof toFactor !== 'number' ||
    toFactor === 0 ||
    fromFactor === 0
  ) {
    return 0;
  }
  return (count * toFactor) / fromFactor;
};

/**
 * Make conversionFactors to relate to the given unit.
 * Provide a count to scale the conversionFactors, if not provided it will be relative to 1 unit of the conversionFactors.
 * If we can't convert to a unit all values will be 0
 * @param factors
 * @param convertTo QuantityUnit to convert to. If passing conversion_factors, it will find a common unit to convert to.
 * @param count
 * @returns
 */
export const convertConversionFactors = <T extends Results | ConversionFactors>(
  factors: T,
  convertTo: QuantityUnit | T,
  count = 1,
): T => {
  if (typeof convertTo !== 'string') {
    if (!isObject(convertTo)) {
      throw new Error('convertTo must be a string or an object');
    }

    // Common unit to used to scale the conversion factors
    const unit = getCommonUnit(factors, convertTo);

    // Run this method again with a selected unit if found else return empty conversion factors
    return isQuantityUnit(unit)
      ? convertConversionFactors(factors, unit, convertTo[unit])
      : ({ ...emptyConversionFactors } as T);
  }

  const to = factors[convertTo];
  const factor = to ? count / to : 0;
  return multiplyConversionFactors(omitUndefined(factors) as T, factor);
};

/**
 * Get a unit that is common to both conversion factors. Can be used to scale/align conversion factors.
 * @param from
 * @param to
 * @returns
 */
const getCommonUnit = (
  from: ConversionFactors,
  to: ConversionFactors,
): QuantityUnit | undefined => {
  // All non-CO2e units in both factors
  const commonKeys = getKeys(omitUndefined(from)).filter(
    (key) => key in to && typeof to[key] === 'number' && !isCO2eUnit(key),
  );

  return (
    commonKeys.find((key) => to[key] === 1) ?? // Prefer units that are 1 in the convertTo object) ??
    commonKeys.find((key) => from[key] === to[key]) ?? // Prefer units that are the same in both objects
    commonKeys[0] // Default to the first common key
  );
};

/**
 * Test if a conversion factor can be converted to a unit.
 * @param conversionFactors
 * @param unit
 * @returns
 */
export const canConvertConversionFactors = (
  conversionFactors: ConversionFactors,
  unit: QuantityUnit | ConversionFactors,
): boolean => {
  if (typeof unit === 'string') {
    if (!isQuantityUnit(unit)) {
      throw new Error(`Unit ${String(unit)} is not a valid quantity unit`);
    }
    return (
      unit in conversionFactors &&
      typeof conversionFactors[unit] === 'number' &&
      isFinite(conversionFactors[unit])
    );
  }
  return getCommonUnit(conversionFactors, unit) !== undefined;
};

export const multiplyConversionFactors = <
  T extends Results | ConversionFactors,
>(
  conversionFactors: T,
  factor: number,
): T => {
  return multiplyProperties(
    conversionFactors,
    factor,
    ...quantityUnits,
    'co2e_total',
  );
};

export const convert = (
  value: number,
  from: ConversionUnits,
  to: ConversionUnits,
): number => {
  return mathJS.unit(value, from).toNumber(to);
};

/**
 * Get densite as kg/m³
 * @param factors
 * @returns
 */
export const getDensity = (factors: ConversionFactors): number | undefined => {
  const { kg, 'm³': m3 } = factors;

  if (typeof kg === 'number' && typeof m3 === 'number') {
    return !m3 ? 0 : kg / m3;
  }
};

/**
 * Determine if cost should be in thousands SEK or not
 */
export const convertToThousandSEK = (
  value: number,
  unitLabel: EmissionCostLabel = 'kSEK',
): number => (unitLabel === 'kSEK' ? value / 1000 : value);

/**
 * If value is a number, it returns value divided by GFA.
 * If value is a ConversionFactors record, it returns a new record with co2e_total and sek_A1-A3 divided by GFA.
 * @param value
 * @param gfa
 * @param convertCostToThousands
 * @returns
 */
export const convertToPerGFA = <T extends number | ConversionFactors>(
  value: T,
  gfa: number,
  convertCostToThousands = false,
) => {
  if (gfa < 1) {
    return value;
  }
  if (typeof value === 'number') {
    return (value / gfa) as T;
  }
  if (typeof value === 'object') {
    const { co2e_total, 'sek_A1-A3': cost } = value;

    if (co2e_total && cost) {
      return {
        ...value,
        co2e_total: co2e_total / gfa,
        'sek_A1-A3': cost / (convertCostToThousands ? 1000 : 1) / gfa,
      };
    }
  }
  return value;
};
