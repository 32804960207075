import {
  List,
  Popover,
  PopoverPosition,
  PopoverProps,
  TextFieldProps,
} from '@mui/material';
import React, { useMemo } from 'react';
import VirtualizedItems from '../VirtualizedItems';
import { useSearch } from '../../hooks/search.hooks';
import SearchField from '../SearchField';
import { ListChildComponentProps } from 'react-window';
import { makeStyles } from 'tss-react/mui';
import { MENU_ITEM_HEIGHT } from '../../../../shared/constants';

enum SelectMenuWidhts {
  small = 200,
  medium = 300,
  large = 600,
}

export type SelectMenuWidth = keyof typeof SelectMenuWidhts;

interface SelectMenuProps<T> {
  items: T[];
  anchor?: HTMLElement;
  anchorPosition?: PopoverPosition;
  disableSearch?: boolean;
  disableVirtualization?: boolean;
  width?: SelectMenuWidth | number;
  onClose: () => void;
  children: (
    items: T[],
    virtualizedItemsProps?: ListChildComponentProps,
  ) => React.ReactNode;
}

const SelectMenu = <T,>({
  items,
  anchor,
  anchorPosition,
  disableSearch,
  disableVirtualization,
  width = 'small',
  onClose,
  children,
}: SelectMenuProps<T>) => {
  const { classes } = useStyles();

  const { filteredItems, searchString, setSearchString } = useSearch({ items });

  const popoverSlotProps = useMemo<PopoverProps['slotProps']>(() => {
    const padding = 6;
    const itemsLength = disableSearch
      ? filteredItems.length
      : filteredItems.length + 1;

    const h = itemsLength * MENU_ITEM_HEIGHT + padding * 2;
    const w = typeof width === 'number' ? width : SelectMenuWidhts[width];

    return {
      paper: {
        sx: {
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 750,
          paddingTop: disableVirtualization ? 0 : padding + 'px',
          height: disableVirtualization ? 'auto' : h,
          width: disableVirtualization ? 'auto' : w,
        },
      },
    };
  }, [disableSearch, filteredItems.length, width, disableVirtualization]);

  const searchTextFieldInputProps = useMemo<TextFieldProps['InputProps']>(
    () => ({
      inputProps: { sx: { padding: '10px 14px' } },
      classes: { notchedOutline: classes.notchedOutline },
    }),
    [classes.notchedOutline],
  );

  return (
    <Popover
      open={!!anchor || !!anchorPosition}
      anchorReference={anchor ? 'anchorEl' : 'anchorPosition'}
      anchorEl={anchor}
      anchorPosition={anchorPosition}
      slotProps={popoverSlotProps}
      onClose={onClose}
    >
      {!disableSearch && (
        <SearchField
          value={searchString}
          onChange={setSearchString}
          InputProps={searchTextFieldInputProps}
        />
      )}
      {disableVirtualization ? (
        <List>{children(filteredItems)}</List>
      ) : (
        <VirtualizedItems items={filteredItems}>
          {(props) => children(filteredItems, props)}
        </VirtualizedItems>
      )}
    </Popover>
  );
};

const useStyles = makeStyles()(() => ({
  notchedOutline: {
    border: 0,
  },
}));

export default SelectMenu;
