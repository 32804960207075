import React, { useMemo } from 'react';
import { DeleteForever, Edit } from '@mui/icons-material';
import { IProjectFolder } from '../../../../../shared/models/folder.interface';
import { useDeleteFolder } from '../../../store/folder/folder.hook';
import { projectActionMenuIconStyles } from '../../../style/constants';
import { KebabButtonMenu } from '../../SelectMenu/KebabButtonMenu';

interface IProjectFolderKebabMenuInput {
  folder: IProjectFolder;
  onEditName: () => void;
  onClose?: () => void;
}

const ProjectFolderKebabMenu: React.FC<IProjectFolderKebabMenuInput> = ({
  folder,
  onEditName,
  onClose,
}) => {
  const { id } = folder;
  const deleteFolder = useDeleteFolder();

  const items = useMemo(
    () => [
      {
        id: 'rename',
        label: 'Rename',
        icon: <Edit {...projectActionMenuIconStyles} />,
        onClick: onEditName,
      },
      {
        id: 'delete',
        label: 'Delete',
        icon: <DeleteForever {...projectActionMenuIconStyles} />,
        onClick: () => deleteFolder(id),
      },
    ],
    [deleteFolder, id, onEditName],
  );

  return <KebabButtonMenu id={id} items={items} onClose={onClose} />;
};

export default ProjectFolderKebabMenu;
