import { useCallback, useMemo } from 'react';
import {
  IElement,
  OneOfElements,
  OneOfPropertyElements,
} from '../../../shared/models/project.interface';
import {
  IElementProperty,
  ElementPropertySource,
} from '../../../shared/models/element_property.interface';
import {
  getElementProperties,
  getElementSourceId,
  isElementPropertySourceCategory,
  isElementPropertyOfSource,
  isElementPropertySourceRecipe,
  limitElementPropertyCountValue,
  getElementAndQuantityProperties,
} from '../../../shared/helpers/element_property_helpers';
import {
  addElementProperties,
  createSourceIdProperty,
} from '../../../shared/helpers/element_property_factory_helpers';
import { last } from 'lodash';
import {
  getBuilding,
  getElementById,
  isElement,
  isOneOfPropertyElements,
} from '../../../shared/helpers/recursive_element_helpers';
import { useUpdateElements } from '../store/project';
import { useUIState } from '../store/ui';
import amplitudeLog from '../amplitude';
import { cloneReplaceById } from '../../../shared/helpers/array_helpers';
import { validateElementProperties } from '../../../shared/validation/element-property.validation';
import { ExpressionErrorMessage } from '../../../shared/helpers/expression_solving_helpers';
import { useErrorSnackbar } from './snackbar.hook';
import { getElementQuantityProperties } from '../../../shared/helpers/element_quantity_helpers';
import { IElementQuantityProperty } from '../../../shared/models/element_quantities.interface';
import { hasCircularDependency } from '../../../shared/helpers/expression_variables_helpers';
import { getId } from '../../../shared/helpers/object_helpers';

interface ElementPropertiesUtils {
  properties: IElementProperty[];
  customProperties: IElementProperty[];
  recipeProperties: IElementProperty[];
  categoryProperties: IElementProperty[];
  quantityProperties: IElementQuantityProperty[];
  addProperty: (type?: ElementPropertySource) => Promise<IElementProperty>;
  updateProperty: (property: IElementProperty) => Promise<{
    property: IElementProperty;
    element: IElement | undefined;
  }>;
  removeProperty: (propertyOrID: IElementProperty | string) => Promise<void>;
}

// TODO: Split this up into smaller hooks
export function useElementPropertiesUtils(
  element: OneOfPropertyElements,
): ElementPropertiesUtils {
  const updateElements = useUpdateElements();
  const { setAddedPropertyId } = useUIState('setAddedPropertyId');
  const errorSnackbar = useErrorSnackbar();
  const properties = getElementProperties(element);
  const categoryProperties = useMemo(
    () => properties.filter(isElementPropertySourceCategory),
    [properties],
  );
  const recipeProperties = useMemo(
    () => properties.filter(isElementPropertySourceRecipe),
    [properties],
  );
  const customProperties = useMemo(
    () => properties.filter((p) => isElementPropertyOfSource(p, undefined)),
    [properties],
  );

  // TODO Remove?
  const quantityProperties = useMemo(
    () => getElementQuantityProperties(element),
    [element],
  );

  const addProperty = useCallback(
    async (source?: ElementPropertySource) => {
      const sourceId = getElementSourceId(element, source);
      const { properties } = addElementProperties(element, {
        ...createSourceIdProperty(source, sourceId),
        inheritFallback: true, // Remove this to disable inheritance for user added properties
      });

      const updatedProject = await updateElements({
        id: element.id,
        properties,
      });

      const updatedElement = getElementById(updatedProject, element.id);

      const newProperty =
        isOneOfPropertyElements(updatedElement) &&
        last(updatedElement.properties);

      if (!newProperty) {
        throw new Error('Could not add property');
      }

      amplitudeLog('Element Property Add', {
        ElementID: element.id,
      });

      setAddedPropertyId(newProperty.id);
      return newProperty;
    },
    [element, setAddedPropertyId, updateElements],
  );

  const updateProperty = useCallback(
    async (
      property: IElementProperty | IElementQuantityProperty,
    ): Promise<{
      property: IElementProperty;
      element: IElement | undefined;
    }> => {
      const props = cloneReplaceById(
        getElementProperties(element),
        property.id,
        property,
      );

      property = limitElementPropertyCountValue(property);

      // Make sure properties is valid before saving
      errorSnackbar(() => validateElementProperties(props));

      const updatedProject = await updateElements({
        id: element.id,
        properties: props,
      });

      const updatedElement = getElementById(
        getBuilding(updatedProject),
        element.id,
      );

      const updatedProperty = getElementProperties(updatedElement)?.find(
        ({ id }) => id === property.id,
      );

      if (!updatedProperty) {
        throw new Error('Could not find updated property');
      }

      amplitudeLog('Element Property Set', {
        ElementID: element.id,
      });

      return {
        property: updatedProperty,
        element: isElement(updatedElement) ? updatedElement : undefined,
      };
    },
    [element, errorSnackbar, updateElements],
  );

  const removeProperty = useCallback(
    async (propertyOrId: IElementProperty | string): Promise<void> => {
      const id = getId(propertyOrId);
      const currentProperties = getElementProperties(element);
      const filteredProperties = currentProperties.filter(
        (p) => p.id !== id || isElementPropertySourceCategory(p),
      );

      if (filteredProperties.length === currentProperties.length) {
        throw new Error('Could not find property to remove');
      }

      await updateElements({
        id: element.id,
        properties: filteredProperties,
      });

      amplitudeLog('Element Property Delete', {
        ElementID: element.id,
      });
    },
    [element, updateElements],
  );

  return useMemo(
    () => ({
      properties,
      customProperties,
      categoryProperties,
      recipeProperties,
      quantityProperties,
      addProperty,
      updateProperty,
      removeProperty,
    }),
    [
      properties,
      customProperties,
      categoryProperties,
      recipeProperties,
      quantityProperties,
      addProperty,
      updateProperty,
      removeProperty,
    ],
  );
}

/**
 * Check if a new expression will cause circular error
 * @param element
 * @param modifiedProperty The property that is being modified. Pass undefined to turn off circular error checking
 * @param expression The new expression in modifiedProperty
 * @returns
 */
export const useCircularError = (
  element: OneOfElements | undefined,
  modifiedProperty: IElementProperty | undefined,
  expression: string | undefined,
): ExpressionErrorMessage | undefined => {
  const properties = useMemo(
    () => getElementAndQuantityProperties(element),
    [element],
  );

  return useMemo(() => {
    if (
      modifiedProperty &&
      hasCircularDependency(modifiedProperty.name, properties, expression)
    ) {
      return ExpressionErrorMessage.Circular;
    }
  }, [expression, modifiedProperty, properties]);
};
