import React, { useCallback, useMemo } from 'react';
import { OneOfProjectListElements } from '../../../../../shared/models/project.interface';
import {
  useProjectState,
  useUpdateProjectAndFolderLocations,
} from '../../../store/project';
import { useFolders } from '../../../store/folder';
import ProjectKebabMenu from './ProjectKebabMenu';
import { Edit } from '@mui/icons-material';
import { projectActionMenuIconStyles } from '../../../style/constants';
import { IMenuItemData } from '../../SelectMenu/KebabButtonMenu';
import { useUserIsOwner } from '../../../hooks/user.hook';

interface IProductElementKebabMenuInput {
  projectListItem: OneOfProjectListElements;
  onEditName: () => void;
  onClose?: () => void;
}

const ProjectInfoKebabMenu: React.FC<IProductElementKebabMenuInput> = ({
  projectListItem,
  onEditName,
  onClose,
}) => {
  const { projectsLookup } = useProjectState('projectsLookup');
  const updateProjectAndFolderLocations = useUpdateProjectAndFolderLocations();

  const projectId = Number(projectListItem.id);
  const folders = useFolders();
  const isOwner = useUserIsOwner(projectId);

  const handleDeleteProject = useCallback(async () => {
    const itemsInParentFolderWithConsecutiveIndices = [
      ...folders,
      ...Object.values(projectsLookup),
    ]
      .filter(
        ({ id, parent_id }) =>
          id !== projectListItem.id && parent_id === projectListItem.parent_id,
      )
      .sort((a, b) => a.location - b.location)
      .map((item, index) => ({ ...item, location: index }));

    return updateProjectAndFolderLocations(
      itemsInParentFolderWithConsecutiveIndices,
    );
  }, [
    folders,
    projectListItem.id,
    projectListItem.parent_id,
    projectsLookup,
    updateProjectAndFolderLocations,
  ]);

  const extraItems = useMemo<IMenuItemData[]>(() => {
    const disabled = !isOwner && !!projectListItem.locked;
    return [
      {
        id: 'rename',
        label: 'Rename',
        placement: 0,
        icon: <Edit {...projectActionMenuIconStyles} />,
        disabled,
        tooltipTitle: disabled
          ? 'Projects that are locked by another user cannot be renamed'
          : '',
        onClick: onEditName,
      },
    ];
  }, [isOwner, onEditName, projectListItem.locked]);

  return (
    <ProjectKebabMenu
      projectId={projectId}
      projectName={projectListItem.name}
      locked={!!projectListItem.locked}
      archived={!!projectListItem.archived}
      onDeleteProjectListItem={handleDeleteProject}
      extraItems={extraItems}
      onClose={onClose}
    />
  );
};

export default ProjectInfoKebabMenu;
