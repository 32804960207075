import {
  ElementKind,
  Project,
  IBuilding,
  FootprintCoordinate,
  ProjectMetadata,
} from '../models/project.interface';
import { omit, omitUndefined } from './object_helpers';
import { SemiPartial } from '../models/type_helpers.interface';
import {
  createElementOfType,
  IFactoryVersion,
} from './element_factory_helpers';
import { ActivityId } from '../models/activity_class.interface';
import { cloneDeep } from 'lodash';
import { getBuilding } from './recursive_element_helpers';
import { getActivityWithUndefinedProps } from './activities.helpers';
import { getTimestamps } from './date.helpers';

type IFactoryBuilding = Partial<Omit<IBuilding, 'versions'>> & {
  versions?: Partial<IFactoryVersion>[];
};

/**
 * Skeleton for a project used to generate a project
 */
export type IFactoryProject = SemiPartial<
  Omit<Project, 'buildings'>,
  'owner'
> & {
  buildings?: IFactoryBuilding[];
};

type IProjectFactory = IFactoryProject & {
  /**
   * Note versions belong to buildings, not projects.
   * This is a shortcut to add versions to the first building.
   */
  versions?: Partial<IFactoryVersion>[];
};

export const initialCoordinates: Readonly<FootprintCoordinate[]> = [
  {
    lat: 59.341518,
    lng: 18.063094,
  },
  {
    lat: 59.341796,
    lng: 18.06412,
  },
  {
    lat: 59.341409,
    lng: 18.064489,
  },
  {
    lat: 59.341205,
    lng: 18.063405,
  },
];

export const defaultMetadata: Readonly<ProjectMetadata> = {
  building_footprint: {
    // area and perimeter should be calculated by the coordinates instead in the future
    area: 1,
    perimeter: 1,
    coordinates: [...initialCoordinates],
  },
  activity_id: ActivityId.PrivateHousing,
  below_ground: 0,
  storeys: [{}],
};

const getBuildingTemplate = (): Readonly<IBuilding> => {
  return {
    meta: defaultMetadata,
    versions: [],
  };
};

const defaultProject: Readonly<
  Omit<Project, 'owner' | 'created_at' | 'updated_at'>
> = {
  id: 0,
  organizations: [],
  name: 'Project',
  buildings: [getBuildingTemplate()],
  template: false,
};

export const createBuilding = (
  initialValues: IFactoryBuilding = {},
  regenerateId = true,
): IBuilding => {
  const versions = initialValues.versions
    ? initialValues.versions.map((v) =>
        createElementOfType(ElementKind.Version, v, regenerateId),
      )
    : [createElementOfType(ElementKind.Version, undefined, regenerateId)];
  return {
    ...cloneDeep(getBuildingTemplate()),
    ...omitUndefined(initialValues),
    versions,
  };
};

/**
 * Create a new project based. By default it will use the predifined defaultValues
 * but it's possible to override parts or the entire project.
 * Pass a mock tree structure to quickly create your own structure of products and elements
 * @param defaults A stripped down tree of elements used to create the entire structure
 * @param regenerateId If ids should be forced to regenerate
 * @returns
 */
export const createProject = (
  defaults: IProjectFactory,
  regenerateId = true,
): Project => {
  const owner = defaults.owner;
  if (!owner) {
    throw new Error('Project must have an owner');
  }

  const buildings = defaults.buildings
    ? defaults.buildings.map((b, index) => {
        // Use shortcut for version
        const versions: Partial<IFactoryVersion>[] | undefined =
          defaults.versions?.length && index === 0
            ? defaults.versions
            : b.versions;
        return createBuilding({ ...b, versions }, regenerateId);
      })
    : [createBuilding({ versions: defaults.versions }, regenerateId)];

  return {
    ...defaultProject,
    ...getTimestamps(),
    // We must omit all properties not belonging to Project interface
    ...omitUndefined(omit(defaults, 'versions')),
    buildings,
    owner,
  };
};

const getStoreyValueOrAlternative = (
  value: number | undefined,
  alternative: number,
  shouldGetAlternative: boolean,
): number | undefined => (shouldGetAlternative ? alternative : value);

const getValueOrUndefined = (
  value: number | null | undefined,
): number | undefined => (value === null || value === 0 ? undefined : value);

/**
 * Use old properties to update new properties, if applicable. TODO: Run in migration?
 * @param project
 * @returns project
 */
export const getBackwardsCompatibleProject = (project: Project): Project => {
  const building = getBuilding(project);
  const { meta } = building;

  const calculatedFootprint = { area: 1, perimeter: 1 }; // should calculate from the coordinates in the future

  const storeyGFAValue = meta.gfa_building
    ? meta.gfa_building / meta.storeys.length
    : calculatedFootprint.area;
  const storeyPerimeterValue = meta.building_perimeter
    ? meta.building_perimeter
    : calculatedFootprint.perimeter;

  meta.activities = meta.activities?.map(getActivityWithUndefinedProps); // for correct expression variable calculations

  if (!meta.building_footprint.area || !meta.building_footprint.perimeter) {
    return {
      ...project,
      buildings: [
        {
          ...building,
          meta: {
            ...meta,
            building_footprint: {
              ...meta.building_footprint,
              area: meta.gfa_building
                ? meta.gfa_building / meta.storeys.length
                : calculatedFootprint.area,
              perimeter:
                meta.building_perimeter ?? calculatedFootprint.perimeter,
            },
            storeys: meta.storeys.map(
              ({ name, gfa, perimeter, inner_height }) => ({
                name: name === null ? undefined : name,
                gfa: getStoreyValueOrAlternative(
                  getValueOrUndefined(gfa),
                  storeyGFAValue,
                  false,
                ),
                perimeter: getStoreyValueOrAlternative(
                  getValueOrUndefined(perimeter),
                  storeyPerimeterValue,
                  false,
                ),
                inner_height: getValueOrUndefined(inner_height),
              }),
            ),
          },
        },
      ],
    };
  }

  return {
    ...project,
    buildings: [{ ...building, meta: { ...meta } }],
  };
};
