import React, { memo, useCallback, useEffect, useMemo } from 'react';
import InlineTextField from '../../InlineTextField';
import { IProjectInfo } from '../../../../../shared/models/project.interface';
import { useIsSelected, useUIState } from '../../../store/ui';
import { useIsReadonly } from '../../../hooks/user.hook';
import { useToggleElementExpanded } from '../../../hooks/expand-elements.hook';
import { isDeactivated } from '../../../../../shared/helpers/element_helpers';
import {
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  LIST_ITEM_HEIGHT,
  LIST_SPACING,
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
} from '../list.constants';
import { useBooleanState } from '../../../hooks/hooks';
import {
  useIsMenuOpen,
  useTriggerContextMenu,
} from '../../../hooks/menu.hooks';
import { useListRowStyles } from '../list.style';
import { useNavigateTo } from '../../../hooks/router.hooks';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import RestrictProjectButton from '../../RestrictProjectButton';
import amplitudeLog from '../../../amplitude';
import { useProjectId, useProjectState } from '../../../store/project';
import { Box, OutlinedTextFieldProps, Tooltip } from '@mui/material';
import ProjectInfoKebabMenu from '../KebabMenus/ProjectInfoKebabMenu';
import ProjectOwnerMenu from '../../ProjectOwnerMenu';
import ExpressionTypography from '../../ExpressionTypography';
import ExpandButton from '../Buttons/ExpandButton';
import DateText from '../../DateText';
import { Row } from '../Row';
import { RowCell } from '../RowCell';
import { IProjectFolder } from '../../../../../shared/models/folder.interface';
import {
  isProjectFolder,
  isProjectInfo,
} from '../../../../../shared/helpers/project-folder.helpers';
import ProjectFolderKebabMenu from '../KebabMenus/ProjectFolderKebabMenu';
import { useFolderState } from '../../../store/folder';

interface ProjectListItemProps {
  item: IProjectInfo | IProjectFolder;
  indentation?: number;
  autoFocus?: boolean;
}

const ProjectListItem: React.FC<ProjectListItemProps> = ({
  item,
  indentation = 0,
  autoFocus,
}) => {
  const { classes: listClasses } = useListRowStyles();
  const readonly = useIsReadonly();
  const {
    setAddedElementId,
    setShowProjectSelector,
    setSelectedProjectFolderId,
  } = useUIState(
    'setAddedElementId',
    'setShowProjectSelector',
    'addedElementId',
    'setSelectedProjectFolderId',
  );
  const { updateFolder } = useFolderState('folders', 'updateFolder');
  const navigateTo = useNavigateTo();
  const toggleElementExpanded = useToggleElementExpanded(item);
  const triggerContextMenu = useTriggerContextMenu(item.id);

  const { name, id, gfa = 0 } = item;
  const projectId = useProjectId();
  const selected = useIsSelected(item);
  const deactivated = isDeactivated(item);
  const isKebabMenuOpen = useIsMenuOpen(id);

  const [hover, startHover, endHover] = useBooleanState(false);

  const [nameTooltipIsOpen, showNameTooltip, hideNameTooltip] =
    useBooleanState(false);

  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);

  const handleFolderClick = useMouseEventCallback(
    () => {
      if (isProjectFolder(item)) {
        setSelectedProjectFolderId(selected ? undefined : item.id);
      }
    },
    { ignoreInputEvents: true, ignoreModalEvents: true },
  );

  const handleNameClick = useMouseEventCallback(
    (e) => {
      if (isProjectFolder(item) || isEditingName) {
        handleFolderClick(e);
        return;
      }
      setShowProjectSelector(false);
      navigateTo({ projectId: id });
    },
    { ignoreInputEvents: true, ignoreModalEvents: true },
  );

  const { updateProjectDetails } = useProjectState('updateProjectDetails');

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        if (autoFocus) {
          e.currentTarget.select();
        }
      },
      onBlur: () => {
        if (autoFocus) {
          setAddedElementId(undefined);
        }
      },
      sx: { cursor: isProjectInfo(item) ? 'pointer' : 'auto', maxWidth: 440 },
    }),
    [autoFocus, item, setAddedElementId],
  );

  const saveName = useCallback(
    async (name: string) => {
      stopEditingName();

      await (isProjectInfo(item)
        ? updateProjectDetails({
            projectId: parseInt(id),
            data: { name },
          })
        : updateFolder({ ...item, name }));

      amplitudeLog('Element Name Set', {
        Name: name,
        ElementID: id,
      });
    },
    [stopEditingName, item, updateProjectDetails, id, updateFolder],
  );

  const editName = useCallback(() => {
    startEditingName();
    endHover();
  }, [endHover, startEditingName]);

  useEffect(() => {
    if (autoFocus) {
      if (isProjectFolder(item)) {
        setSelectedProjectFolderId(item.id);
        setAddedElementId(undefined);
        return;
      }
      startEditingName();
    }
  }, [
    autoFocus,
    item,
    setAddedElementId,
    setSelectedProjectFolderId,
    startEditingName,
  ]);

  return (
    <Row
      padding
      spacing={LIST_SPACING}
      classes={listClasses}
      deactivated={deactivated}
      height={LIST_ITEM_HEIGHT}
      hover={hover}
      selected={selected}
      onContextMenu={triggerContextMenu}
      onDoubleClick={toggleElementExpanded}
      onMouseLeave={endHover}
      onMouseEnter={startHover}
      onClick={handleFolderClick}
    >
      <RowCell
        width={ROOT_CELL_WIDTH.CONTENT}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.CONTENT}
      >
        <Row height={LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
          <RowCell width={ROOT_CELL_WIDTH.ICON} indentation={indentation}>
            <RestrictProjectButton hover={hover} project={item} />
          </RowCell>

          <RowCell width={ROOT_CELL_WIDTH.ICON}>
            <ExpandButton element={item} disabled={isProjectInfo(item)} />
          </RowCell>

          <RowCell
            width={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
          >
            <Tooltip
              open={nameTooltipIsOpen}
              onMouseOver={showNameTooltip}
              onMouseOut={hideNameTooltip}
              title={
                isProjectInfo(item) && !isEditingName ? 'Open project' : ''
              }
            >
              <Box width={!isEditingName ? 'auto' : '100%'}>
                <InlineTextField
                  autoFocus
                  variant="subtitle1"
                  value={name}
                  editing={isEditingName}
                  disabled={readonly || !isEditingName}
                  allowClickEventWhenDisabled
                  textFieldProps={nameInputProps}
                  fullWidth={isEditingName}
                  bold={id === String(projectId) && !isEditingName}
                  onSave={saveName}
                  onCancel={stopEditingName}
                  onClick={handleNameClick}
                />
              </Box>
            </Tooltip>
          </RowCell>

          <RowCell width={CONTENT_CELL_WIDTH.DATE} align="right">
            <DateText
              date={item.updated_at}
              relativeToCurrentTime
              tootltipPrefix="Updated at"
            />
          </RowCell>

          <RowCell
            width={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
            responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
            align="right"
          >
            <Tooltip title="Gross floor area">
              <Box>
                <ExpressionTypography value={gfa} unit={'m²'} />
              </Box>
            </Tooltip>
          </RowCell>
        </Row>
      </RowCell>
      <RowCell
        width={ROOT_CELL_WIDTH.BAR}
        responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        align="right"
      >
        {isProjectInfo(item) && (
          <ProjectOwnerMenu projectInfo={item} onClose={endHover} />
        )}
      </RowCell>

      <RowCell width={CONTENT_CELL_WIDTH.ICON}>
        {(isKebabMenuOpen || hover) &&
          (isProjectInfo(item) ? (
            <ProjectInfoKebabMenu
              projectListItem={item}
              onEditName={editName}
              onClose={endHover}
            />
          ) : (
            <ProjectFolderKebabMenu
              folder={item}
              onEditName={editName}
              onClose={endHover}
            />
          ))}
      </RowCell>
    </Row>
  );
};

export default memo(ProjectListItem);
