import {
  ElementKind,
  IBuilding,
  IBuildingVersion,
  IElementID,
  IID,
  IProductElement,
  OneOfElements,
  OneOfParentElements,
  Project,
  ProjectID,
  IProjectInfo,
  OneOfProjectListElements,
  IElement,
} from '../../../../shared/models/project.interface';
import {
  getId,
  omitUndefined,
  pick,
} from '../../../../shared/helpers/object_helpers';
import { useMemoDeepEqual, useObjectMemo } from '../../hooks/hooks';
import { fetchProject, useProjectStateStore } from './project.store';
import {
  CreateProjectPayload,
  IProjectState,
  IUpdateProjectOptions,
  ProjectsLookup,
} from './project-state.model';
import { useCallback, useMemo } from 'react';
import { getProductById } from '../../../../shared/helpers/product_helpers';
import {
  addElements,
  removeElements,
  sortProjectsByLastUpdated,
  updateElements,
} from '../../../../shared/helpers/project_helpers';
import {
  usePromiseSnackbar,
  useErrorSnackbar,
  ISnackbarOptions,
} from '../../hooks/snackbar.hook';
import { getProductsLookup } from '../product';
import { IFactoryProductElement } from '../../../../shared/models/element_factory_helpers.interface';
import {
  CreatedElement,
  IFactoryElement,
  IFactoryVersion,
  isFactoryElement,
  OneOfFactoryElements,
} from '../../../../shared/helpers/element_factory_helpers';
import {
  findElement,
  getAllBuildingVersions,
  getBuilding,
  getBuildingVersionById,
  getElementById,
  getParentElement,
  isBuildingVersionElement,
  isProductElement,
} from '../../../../shared/helpers/recursive_element_helpers';
import { StoreErrorMessage } from '../store_errors';
import { useUIState, getSelectedVersion } from '../ui';
import { useNavigateTo } from '../../hooks/router.hooks';
import { useUsedProductIds } from '../../hooks/useUsedProductIds';
import { getRecipes, useRecipes } from '../recipe/recipe.hook';
import { ElementCategoryID } from '../../../../shared/models/element_categories.interface';
import { useIsConfirmed } from '../../hooks/confirm.hook';
import { getElementNames } from '../../../../shared/helpers/element_helpers';
import amplitudeLog from '../../amplitude';
import { useNavigate } from 'react-router-dom';
import { useLastAddedProjectIds } from '../../hooks/useUsedProjectIds';
import { getBuildingGFA } from '../../../../shared/helpers/expression_variables_helpers';
import { getMainCategoryElement } from '../../../../shared/templates/categories';
import { exportProject } from '../../helpers/import_helpers';
import { useConfig } from '../../providers/ConfigProvider';
import { getFolders, useFolderState } from '../folder';
import { useProjectSelectorDrop } from '../../hooks/droppable.hook';
import { enrichProject } from '../../../../shared/helpers/project-pipeline.helpers';
import { IInsertSortPlacement } from '../../../../shared/models/sort.interface';
import { TMP_ELEMENT_ID } from '../../../../shared/constants';
import { validateFolderList } from '../../../../shared/validation/project-folder.validation';
import { getFlattenedSortedElements } from '../../hooks/filter-elements.hook';
import { getItemById } from '../../../../shared/helpers/array_helpers';
import { isElementVersionElement } from '../../../../shared/helpers/element-version.helpers';
import { useIsReadonly } from '../../hooks/user.hook';
import { getLastSelectedElementCategoryId } from '../../hooks/element-category.hook';
import { required } from '../../../../shared/helpers/function_helpers';
import { findFreeName } from '../../../../shared/helpers/string_helpers';
import { isElementCategoryID } from '../../../../shared/helpers/element_category_helpers';
import { capitalize } from 'lodash';

type UseUpdateElementsFn = <T extends OneOfElements>(
  ...elements: ((Partial<T> & IID) | undefined)[]
) => Promise<Project>;

interface IAddElementOptions {
  placement?: IInsertSortPlacement;
  isSibling?: boolean;
  navigate?: boolean;
  regenerateIds?: boolean;
  snackbarOptions?: ISnackbarOptions;
}

export type UseAddElementFn = <T extends OneOfFactoryElements>(
  parentOrSibling: OneOfElements,
  defaults: T,
  options?: IAddElementOptions,
) => Promise<{ element: CreatedElement<T['kind']>; project: Project }>;

type UseAddStandardElementFn = (
  parentOrSibling: OneOfElements,
  defaults: Partial<IFactoryElement>,
  options?: IAddElementOptions,
) => Promise<{ element: IElement; project: Project }>;

type UseAddVersionFn = (
  partial?: Partial<IFactoryVersion>,
) => Promise<{ element: IBuildingVersion; project: Project }>;

type UseAddProductElementFn = (
  parentOrSibling: OneOfElements,
  defaults: Partial<IFactoryProductElement>,
  isSibling?: boolean,
) => Promise<{ element: IProductElement; project: Project }>;
interface IRemoveElementsOptions {
  showConfirm?: boolean;
  navigate?: boolean;
}

type UseRemoveElementsFn = (...elementIds: IElementID[]) => Promise<Project>;

const projectSelector = (state: IProjectState): Project =>
  (state as unknown as IProjectState).project;

const projectsLookupSelector = (state: IProjectState): ProjectsLookup =>
  (state as unknown as IProjectState).projectsLookup;

/**
 * Create a new object including a subset of propetries from an object
 * @param obj
 * @param keys
 * @returns
 */
export function useProjectState<K extends keyof IProjectState>(
  ...keys: K[]
): Pick<IProjectState, K> {
  // Create a memoed selector function to pick properties
  const selector = useMemoDeepEqual(
    () => (o: IProjectState) => (keys.length ? pick(o, ...keys) : o),
    keys,
  );
  return useProjectStateStore(selector);
}

/**
 * Get project without causing rerenders
 * @returns
 */
export const getProject = (): Project =>
  projectSelector(useProjectStateStore.getState());

/**
 * Get project without causing rerenders
 * @returns
 */
export const getProjectsLookup = (): ProjectsLookup =>
  projectsLookupSelector(useProjectStateStore.getState());

/**
 * Get getFetchProject without causing rerenders
 * @returns
 */
export const getFetchProject = (): IProjectState['fetchProject'] =>
  useProjectStateStore.getState().fetchProject;

/**
 * Get latest project and trigger a rerender when it changes
 * @returns
 */
export const useProject = (): Project => useProjectStateStore(projectSelector);

export const useProjectLookup = (): ProjectsLookup =>
  useProjectStateStore(projectsLookupSelector);

/**
 * Get an array of projects
 * @returns
 */
export const useProjects = (): IProjectInfo[] => {
  const projectsLookup = useProjectLookup();
  return useMemo(() => Object.values(projectsLookup), [projectsLookup]);
};

/**
 * Get current project id. Only triggers rerenders when project id changes
 * @returns
 */
export const useProjectId = (): ProjectID =>
  useProjectStateStore((state) => state.project.id);

export const useBuilding = (): IBuilding => getBuilding(useProject());

export const useBuildingMetadata = (): IBuilding['meta'] => useBuilding().meta;

export const useUpdateProject = (): ((
  project: Project,
  options?: ISnackbarOptions & IUpdateProjectOptions,
) => Promise<Project>) => {
  const promiseSnackbar = usePromiseSnackbar();
  const { updateProject, updateProjectLocally } = useProjectState(
    'updateProject',
    'updateProjectLocally',
  );
  const { setAddedElementId } = useUIState('setAddedElementId');

  const readonly = useIsReadonly();

  return useCallback(
    async (project, options: ISnackbarOptions & IUpdateProjectOptions = {}) => {
      if (readonly) {
        throw new Error('User have no right to update project');
      }

      // Don't send project to server if it hasn't changed
      if (project === getProject()) {
        return project;
      }

      // Don't send temporary project to server
      if (findElement(project, TMP_ELEMENT_ID)) {
        return updateProjectLocally({
          event: 'update',
          itemOrId: getEnrichedProject(project),
        });
      }

      const updatedProject = await promiseSnackbar(
        () =>
          updateProject(
            getEnrichedProject(project),
            pick(options, 'skipServerUpdate'),
          ),
        options,
      );

      // Clear any added element ID state since something have changed since last element added
      setAddedElementId(undefined);

      return updatedProject;
    },
    [
      promiseSnackbar,
      readonly,
      setAddedElementId,
      updateProject,
      updateProjectLocally,
    ],
  );
};

export const useCreateProject = (): ((
  payload: CreateProjectPayload,
  snackbarOptions?: ISnackbarOptions,
) => Promise<Project>) => {
  const {
    showProjectSelector,
    setShowProjectDetailsEditor,
    setAddedElementId,
  } = useUIState(
    'showProjectSelector',
    'setShowProjectDetailsEditor',
    'setAddedElementId',
  );
  const createProject = useProjectStateStore((state) => state.createProject);
  const navigate = useNavigate();
  const promiseSnackbar = usePromiseSnackbar();
  const onProjectSelectorDrop = useProjectSelectorDrop();
  const [, addProjectID] = useLastAddedProjectIds();

  return useCallback(
    async (
      payload,
      snackbarOptions = {
        errorMessage: 'Failed to create project',
        successMessage: 'New project created',
        logError: true,
      },
    ) => {
      const { duplicate, projectPartial } = payload;

      try {
        const project = await promiseSnackbar(
          () => createProject(payload),
          snackbarOptions,
        );
        const parsedProjectId = String(project.id);

        if (!showProjectSelector) {
          navigate(`/projects/${project.id}`);
          setShowProjectDetailsEditor(true);
        }

        // Place a duplicate project below the original
        onProjectSelectorDrop(
          parsedProjectId,
          String(projectPartial?.id),
          'below',
        );

        addProjectID(parsedProjectId);
        setAddedElementId(parsedProjectId);

        amplitudeLog(`Project ${duplicate ? 'duplicate' : 'create'}`, {
          ProjectId: project.id,
        });

        return project;
      } catch (err) {
        console.error(err);
        navigate('/');

        return {} as Project;
      }
    },
    [
      addProjectID,
      createProject,
      navigate,
      onProjectSelectorDrop,
      promiseSnackbar,
      setAddedElementId,
      setShowProjectDetailsEditor,
      showProjectSelector,
    ],
  );
};

export const useDuplicateProject = (): ((
  project: Project,
  snackbarOptions?: ISnackbarOptions,
) => Promise<Project>) => {
  const { projectsLookup } = useProjectState('projectsLookup');
  const { folders } = useFolderState('folders');
  const createProject = useCreateProject();

  return useCallback(
    (project, snackbarOptions) => {
      const projectSelectorItems = [
        ...folders,
        ...Object.values(projectsLookup),
      ].filter(({ parent_id }) => parent_id === project.parent_id);

      const newProject: Project = {
        ...project,
        locked: false,
        archived: false,
        location: projectSelectorItems.length + 1,
      };

      return createProject(
        {
          projectPartial: newProject,
          duplicate: true,
        },
        snackbarOptions,
      );
    },
    [createProject, folders, projectsLookup],
  );
};

export const useDeleteProject = (): ((
  id: ProjectID,
  snackbarOptions?: ISnackbarOptions,
) => Promise<void>) => {
  const promiseSnackbar = usePromiseSnackbar();
  const deleteProject = useProjectStateStore((state) => state.deleteProject);
  const navigate = useNavigate();

  return useCallback(
    (
      projectId: ProjectID,
      snackbarOptions = {
        errorMessage: 'Failed to delete project',
        successMessage: 'Project deleted',
        logError: true,
      },
      ...rest
    ): Promise<void> => {
      return promiseSnackbar(async () => {
        await deleteProject(projectId, ...rest);

        const selectedProject = getProject();

        if (selectedProject.id === projectId) {
          navigate('/');
        }
        amplitudeLog('Project Delete', {
          ProjectId: projectId,
        });
      }, snackbarOptions);
    },
    [deleteProject, navigate, promiseSnackbar],
  );
};

export function useAddElement(): UseAddElementFn {
  const updateProject = useUpdateProject();
  const {
    selectedElementCategoryId,
    setAddedElementId,
    setAddedElementOriginalId,
  } = useUIState(
    'selectedElementCategoryId',
    'setAddedElementId',
    'setAddedElementOriginalId',
  );
  const navigateTo = useNavigateTo();

  // TODO: Refactor
  const addElementFn: UseAddElementFn = useCallback(
    async <T extends OneOfFactoryElements>(
      parentOrSibling: OneOfElements,
      defaults: T,
      {
        placement = 'first',
        isSibling = false,
        navigate = true,
        snackbarOptions,
        regenerateIds,
      }: IAddElementOptions = {},
    ) => {
      // Not hooks so we must get them inside the function to get the latest values
      const project = getProject();
      const version = getSelectedVersion();
      const sibling =
        isSibling && !isBuildingVersionElement(parentOrSibling)
          ? parentOrSibling
          : undefined;

      if (!version) {
        throw new Error(StoreErrorMessage.VersionUndefined);
      }

      const parent = getParent(
        version,
        parentOrSibling,
        isSibling,
        selectedElementCategoryId,
      );

      const defaultElement = omitUndefined(defaults) as T;

      // Add a default category id to make sure no elements are added without a category
      if (
        isFactoryElement(defaultElement) &&
        !isElementCategoryID(defaultElement.category_id)
      ) {
        defaultElement.category_id =
          getLastSelectedElementCategoryId() ?? ElementCategoryID.Beam;
      }

      const { root: projectWithAddedElements, addedElements } = addElements(
        project,
        parent,
        { placement, sibling, regenerateIds },
        defaultElement,
      );

      // Send to server
      const updatedProject = await updateProject(
        projectWithAddedElements,
        snackbarOptions,
      );

      const element: CreatedElement<T['kind']> = getElementById(
        updatedProject,
        required(addedElements[0]).id,
      );

      if (!element) {
        throw new Error('Element was not added');
      }

      if (navigate) {
        navigateTo(
          isBuildingVersionElement(element)
            ? {
                versionId: element.id,
                mainCategoryId: 'none',
                elementId: 'none',
              }
            : { elementId: element.id },
        );
      }

      if (element.id !== TMP_ELEMENT_ID) {
        // Set added ID so we can make user focus on its name input
        setAddedElementId(element.id);
        setAddedElementOriginalId(defaults.id);
      }

      return { element, project: updatedProject };
    },
    [
      selectedElementCategoryId,
      updateProject,
      navigateTo,
      setAddedElementId,
      setAddedElementOriginalId,
    ],
  );
  return addElementFn;
}

export function useAddStandardElement(): UseAddStandardElementFn {
  const addElement = useAddElement();

  return useCallback(
    (parentOrSibling, defaults, options) => {
      return addElement(
        parentOrSibling,
        { ...defaults, kind: ElementKind.Element },
        options,
      );
    },
    [addElement],
  );
}

export function useAddVersion(): UseAddVersionFn {
  const addElement = useAddElement();

  return useCallback(
    async (partial: Partial<IFactoryVersion> = {}) => {
      const version = getSelectedVersion();

      // TODO Version is pointless here, remove need for it
      if (!version) {
        throw new Error(StoreErrorMessage.VersionUndefined);
      }
      const { element, project } = await addElement(version, {
        ...partial,
        kind: ElementKind.Version,
      });

      return { element, project };
    },
    [addElement],
  );
}

export function useAddProductElement(): UseAddProductElementFn {
  const addElement = useAddElement();
  const [, addUsedProductId] = useUsedProductIds();

  return useCallback(
    async (
      parentOrSibling: OneOfElements,
      defaults: Partial<IFactoryProductElement>,
      isSibling = false,
    ) => {
      const version = getSelectedVersion();
      const { product_id, unit } = defaults;
      const productLookup = getProductsLookup();

      if (!product_id) {
        throw new Error('Product ID is required');
      }

      const product = getProductById(productLookup, version, product_id);

      // Make sure unit is existing
      defaults.unit =
        unit && product.conversion_factors[unit] ? unit : product.unit;

      const { element, project } = await addElement(
        parentOrSibling,
        {
          ...defaults,
          kind: ElementKind.Product,
          unit: unit && product.conversion_factors[unit] ? unit : product.unit,
        },
        { isSibling },
      );

      addUsedProductId(product_id);

      return { element, project };
    },
    [addElement, addUsedProductId],
  );
}

export function useRemoveElements({
  showConfirm = false,
  navigate = true,
}: IRemoveElementsOptions = {}): UseRemoveElementsFn {
  const updateProject = useUpdateProject();
  const errorSnackbar = useErrorSnackbar();
  const confirm = useIsConfirmed();
  const navigateTo = useNavigateTo();

  return useCallback(
    async (...elementIds: IElementID[]) => {
      if (!elementIds.length) {
        throw new Error('No elements to remove');
      }

      const project = getProject();
      const selectedVersion = getSelectedVersion();
      const version = getBuildingVersionById(project, elementIds[0]);

      if (!selectedVersion) {
        throw new Error(StoreErrorMessage.VersionUndefined);
      }

      const names =
        version?.name ?? getElementNames(selectedVersion, ...elementIds);

      const isElementVersion = isElementVersionsDelete(elementIds);

      if (
        showConfirm &&
        !(await confirm({
          title: `Delete ${names}`,
          description: `Are you sure you want to delete ${isElementVersion ? 'version ' : ''}${names}?`,
          confirmationText: 'Delete',
        }))
      ) {
        return project;
      }

      const updatedProject = errorSnackbar(() =>
        removeElements(project, ...elementIds),
      );

      for (const id of elementIds) {
        amplitudeLog('Element Delete', {
          ElementID: id,
        });
        if (navigate) {
          const element = getElementById(project, id);

          navigateTo(
            isBuildingVersionElement(element)
              ? { mainCategoryId: 'none', elementId: 'none' }
              : { elementId: 'none' },
          );
        }
      }

      return updateProject(updatedProject);
    },
    [confirm, errorSnackbar, navigate, navigateTo, showConfirm, updateProject],
  );
}

const isElementVersionsDelete = (elementIds: IElementID[]): boolean => {
  if (elementIds.length === 1) {
    const versions = getAllBuildingVersions(getProject());
    const firstId = elementIds[0];

    const element = firstId
      ? getItemById([...versions, ...getFlattenedSortedElements()], firstId)
      : undefined;

    return isElementVersionElement(element);
  }
  return false;
};

/**
 * Update elements in project.
 * Also updates product records in project.
 * @returns
 */
export function useUpdateElements(
  options: IUpdateProjectOptions = {},
): UseUpdateElementsFn {
  const updateProject = useUpdateProject();
  const memoOptions = useObjectMemo(options);

  return useCallback(
    async (...elementChanges) => {
      const updatedProject = updateElements(getProject(), ...elementChanges);

      return await updateProject(updatedProject, memoOptions);
    },
    [memoOptions, updateProject],
  );
}

const getParent = (
  version: IBuildingVersion,
  element: OneOfElements,
  isSibling: boolean,
  mainCategoryId: ElementCategoryID | undefined,
): OneOfParentElements => {
  if (isBuildingVersionElement(element)) {
    return getMainCategoryElement(version, mainCategoryId, true) ?? version;
  }
  if (isSibling || isProductElement(element)) {
    const parent = getParentElement(version, element);

    if (!parent) {
      throw new Error('Parent element not found');
    }
    return parent;
  }

  return element;
};

/**
 * Make sure products and expressions are updated.
 * Made to not trigger any redundant rerenders.
 * @param project
 * @returns
 */
export const getEnrichedProject = (project: Project): Project => {
  const prevProject = getProject();
  const productRecord = getProductsLookup();
  const recipes = getRecipes();
  return enrichProject(project, {
    prevProject,
    productRecord,
    recipes,
  });
};

export const useSortedByLastUpdatedProjects = (): IProjectInfo[] => {
  const { projectsLookup } = useProjectState('projectsLookup');

  return useMemo(() => {
    const projects = Object.values(projectsLookup);

    return sortProjectsByLastUpdated(projects);
  }, [projectsLookup]);
};

/**
 * @returns total amount of gross floor area in square meters for available projects,
 * excluding readonly projects
 */
export const useProjectsTotalGFA = (): number => {
  const { projectsLookup } = useProjectState('projectsLookup');

  return Object.values(projectsLookup).reduce(
    (acc, project) =>
      acc + (!project.archived && project.gfa ? project.gfa : 0),
    0,
  );
};

export const useProjectBuildingGFA = (): number =>
  getBuildingGFA(getBuilding(useProject()).meta);

export const useExportProject = (): ((id: ProjectID) => Promise<void>) => {
  const recipes = useRecipes();
  const [config] = useConfig();

  return useCallback(
    async (id) => {
      const project = await fetchProject(id);
      return exportProject(getEnrichedProject(project), recipes, config);
    },
    [config, recipes],
  );
};

export const useProjectRestriction = (): ((
  id: ProjectID,
  name: string,
  action: { lock?: boolean; archive?: boolean },
  shouldConfirm?: boolean,
) => Promise<void>) => {
  const confirm = useIsConfirmed();
  const promiseSnackbar = usePromiseSnackbar();
  const { updateProjectDetails } = useProjectState('updateProjectDetails');

  return useCallback(
    async (id, name, { lock, archive }, shouldConfirm = false) => {
      const isArchiveAction = archive !== undefined && !lock;

      const lockAction = lock ? 'lock' : 'unlock';
      const archiveAction = archive ? 'archive' : 'unarchive';
      const action = isArchiveAction ? archiveAction : lockAction;

      const confirmed = shouldConfirm
        ? await confirm({
            title: `${capitalize(action)} ${name}`,
            confirmationText: capitalize(action),
            description: getProjectRestrictionDescription(action),
          })
        : true;

      if (confirmed) {
        promiseSnackbar(
          updateProjectDetails({
            projectId: id,
            data: { archived: archive, locked: lock },
          }),
          {
            successMessage: getProjectRestrictionSuccessMessage(action),
            errorMessage: `Failed to ${action} project`,
          },
        );
        amplitudeLog(`${action} project`, {
          ProjectId: id,
          ProjectName: name,
        });
      }
    },
    [confirm, promiseSnackbar, updateProjectDetails],
  );
};

type RestrictAction = 'lock' | 'unlock' | 'archive' | 'unarchive';

const getProjectRestrictionDescription = (action: RestrictAction) => {
  switch (action) {
    case 'lock':
      return 'Locked projects can only be edited by the project owner';
    case 'archive':
      return 'Archived projects cannot be edited by any user';
    default:
      return 'The project will become possible to edit again';
  }
};

const getProjectRestrictionSuccessMessage = (action: RestrictAction) => {
  switch (action) {
    case 'lock':
      return 'The project is locked and can only be edited by the owner (you)';
    case 'unlock':
      return 'The project is unlocked and can be edited by anyone in your organisation';
  }
};

export const useUpdateProjectAndFolderLocations = () => {
  const { updateProjectAndFolderLocations } = useProjectState(
    'updateProjectAndFolderLocations',
  );

  return useCallback(
    (itemsToMove: OneOfProjectListElements[]) => {
      const modifiedIds = itemsToMove.map(getId);
      const unmodifiedFolders = getFolders().filter(
        (folder) => !modifiedIds.includes(folder.id),
      );

      validateFolderList([...unmodifiedFolders, ...itemsToMove]);

      return updateProjectAndFolderLocations(itemsToMove);
    },
    [updateProjectAndFolderLocations],
  );
};

export const useFindFreeProjectName = (): ((name?: string) => string) =>
  useCallback((name) => {
    const names = Object.values(getProjectsLookup()).map(
      (project) => project.name,
    );
    return findFreeName(names, name ?? 'Project 1');
  }, []);
