import React, { useMemo } from 'react';
import { Close, FileCopy } from '@mui/icons-material';
import { IProductElement } from '../../../../../shared/models/project.interface';
import { isGeneratedProductElement } from '../../../../../shared/helpers/element_helpers';
import amplitudeLog from '../../../amplitude';
import { useIsReadonly } from '../../../hooks/user.hook';
import { useAddElement, useRemoveElements } from '../../../store/project';
import { KebabButtonMenu } from '../../SelectMenu/KebabButtonMenu';

interface IProductElementKebabMenuInput {
  element: IProductElement;
  onClose?: () => void;
}

const ProductElementKebabMenu: React.FC<IProductElementKebabMenuInput> = ({
  element,
  onClose,
}) => {
  const addElement = useAddElement();
  const removeElements = useRemoveElements({ showConfirm: true });
  const readonly = useIsReadonly();
  const { id } = element;

  const items = useMemo(
    () => [
      {
        onClick: () => {
          amplitudeLog('Product Duplicate');
          return addElement(element, element, {
            isSibling: true,
            placement: 'after',
          });
        },
        icon: <FileCopy color="secondary" />,
        id: 'element_product.duplicate',
        label: 'Duplicate product',
      },
      {
        onClick: () => removeElements(id),
        icon: <Close color="secondary" />,
        id: 'element_product.remove',
        label: 'Remove product',
        disabled: readonly,
      },
    ],
    [readonly, addElement, element, removeElements, id],
  );

  if (isGeneratedProductElement(element)) {
    return null;
  }

  return (
    <KebabButtonMenu
      id={id}
      items={items}
      onClose={onClose}
      kebabButtonProps={{ tooltipTitle: 'Product options' }}
    />
  );
};

export default ProductElementKebabMenu;
