import { ArrowDropDown } from '@mui/icons-material';
import { Box, Button, ButtonProps, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import { EllipsisText } from '../EllipsisText';
import { makeStyles } from 'tss-react/mui';
import SelectMenu, { SelectMenuWidth } from './SelectMenu';
import { ListChildComponentProps } from 'react-window';
import { useIsReadonly } from '../../hooks/user.hook';

interface TextSelectMenuProps<T> {
  items: T[];
  anchor: HTMLElement | undefined;
  label: string;
  tooltip?: string;
  buttonProps?: ButtonProps;
  menuWidth?: SelectMenuWidth | number;
  setAnchor: (anchor: HTMLElement | undefined) => void;
  children: (
    items: T[],
    virtualizedItemsProps?: ListChildComponentProps,
  ) => React.ReactNode;
}

const TextButtonMenu = <T,>({
  items,
  anchor,
  label,
  tooltip,
  buttonProps,
  menuWidth,
  children,
  setAnchor,
}: TextSelectMenuProps<T>) => {
  const { classes } = useStyles();
  const readonly = useIsReadonly();

  const closeMenu = useCallback(() => {
    setAnchor(undefined);
  }, [setAnchor]);

  const openMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchor(e.currentTarget);
    },
    [setAnchor],
  );

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={tooltip}>
        <Button
          {...buttonProps}
          variant="text"
          size="small"
          color="inherit"
          disabled={readonly || items.length === 0}
          classes={{
            root: classes.button,
            endIcon: classes.endIcon,
          }}
          endIcon={<ArrowDropDown />}
          onClick={openMenu}
        >
          <EllipsisText>{label}</EllipsisText>
        </Button>
      </Tooltip>

      <SelectMenu
        anchor={anchor}
        items={items}
        width={menuWidth}
        disableSearch={items.length < 25}
        disableVirtualization={items.length < 25}
        onClose={closeMenu}
      >
        {children}
      </SelectMenu>
    </Box>
  );
};

const useStyles = makeStyles()(({ spacing, palette }) => ({
  button: {
    fontWeight: 400,
    fontSize: 12,
    paddingRight: spacing(2.5),
    maxWidth: 175,
    textTransform: 'none',
    border: `1px solid transparent`,

    '&:hover': {
      backgroundColor: 'transparent',
      border: `1px solid ${palette.text.secondary}`,
    },
  },
  endIcon: {
    marginLeft: spacing(1),
    color: palette.text.secondary,
  },
  itemButton: {
    fontSize: 12,
  },
}));

export default TextButtonMenu;
