import React, { CSSProperties, FC, useCallback, useMemo } from 'react';
import {
  Box,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Checkbox,
  SxProps,
  Theme,
  BoxProps,
} from '@mui/material';
import { mouseEventHandler } from '../../../../shared/helpers/event_helpers';
import { useMouseEventCallback } from '../../hooks/events.hook';
import { useObjectMemo } from '../../hooks/hooks';
import SelectMenu, { SelectMenuWidth } from './SelectMenu';
import {
  useMenuPosition,
  menuMouseHandler,
  openMenu,
  closeMenu,
} from '../../hooks/menu.hooks';
import { EllipsisText } from '../EllipsisText';
import KebabButton, { KebabButtonProps } from './KebabButton';

export interface IMenuItemData {
  id: string;
  label: string;
  icon?: JSX.Element;
  disabled?: boolean;
  tooltipTitle?: string;

  /**
   * Index of the item in the menu.
   * If not provided, the item will be added to the end of the menu.
   */
  placement?: number;

  /**
   * Pass boolean to show checkbox (undefined will not show checkbox)
   */
  checked?: boolean;
  checkboxColor?: string;
  onClick: (...args: any[]) => Promise<any> | void | undefined;
}

interface IKebabMenuInput extends Omit<BoxProps, 'id'> {
  id: string | number;
  /**
   * If no items are provided, the kebab menu will be disabled
   */
  items: IMenuItemData[];
  hidden?: boolean;
  menuWidth?: SelectMenuWidth | number;
  kebabButtonProps?: Partial<KebabButtonProps>;
  onClose?: () => void;
  onOpen?: () => void;
}

export const KebabButtonMenu: FC<IKebabMenuInput> = ({
  id,
  items,
  hidden,
  menuWidth,
  kebabButtonProps,
  onClose,
  onOpen,
  ...boxProps
}) => {
  if (!id) {
    throw new Error('KebabMenu: key is required');
  }
  const position = useMenuPosition(id);

  const openKebab: KebabButtonProps['onClick'] = useMouseEventCallback(
    (e) => {
      const anchorPosition = menuMouseHandler(e);
      if (anchorPosition) {
        openMenu({ anchorPosition, id });
      }
      onOpen?.();
    },
    { stopPropagation: true },
  );

  const closeKebab = useCallback(() => {
    onClose?.();
    closeMenu();
  }, [onClose]);

  const sx: SxProps<Theme> = useObjectMemo({
    opacity: hidden ? 0 : 1,
    transition: 'opacity 0.3s',
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      m="6px 8px"
      width={20}
      sx={sx}
      {...boxProps}
    >
      <KebabButton
        disabled={items.length === 0}
        onClick={openKebab}
        {...kebabButtonProps}
      />
      <SelectMenu
        disableSearch
        disableVirtualization
        anchorPosition={position}
        items={items}
        width={menuWidth}
        onClose={closeKebab}
      >
        {(filteredItems, virtualizedItemsProps) => {
          if (!virtualizedItemsProps) {
            return items.map((item) => <ListItem key={item.id} item={item} />);
          }
          const { index, style } = virtualizedItemsProps;
          const item = filteredItems[index];
          return item ? <ListItem item={item} style={style} /> : null;
        }}
      </SelectMenu>
    </Box>
  );
};

const iconStyles: SxProps = {
  padding: 0,
} as const;

interface ListItemProps {
  item: IMenuItemData;
  style?: CSSProperties;
}

const ListItem = ({ item, style }: ListItemProps) => {
  const {
    onClick,
    icon,
    id,
    label,
    disabled,
    checked,
    checkboxColor,
    tooltipTitle,
  } = item;

  const handleItemClick = useCallback(
    (onClick: (...args: any[]) => Promise<any> | void | undefined) =>
      mouseEventHandler(async () => {
        await onClick();
        closeMenu();
      }),
    [],
  );

  return (
    <Tooltip style={style} key={id} title={tooltipTitle} disableInteractive>
      <Box>
        <MenuItem onClick={handleItemClick(onClick)} disabled={disabled}>
          {typeof checked === 'boolean' && (
            <ListItemIcon>
              <CheckboxButton checked={checked} checkboxColor={checkboxColor} />
            </ListItemIcon>
          )}

          {icon && <ListItemIcon sx={iconStyles}>{icon}</ListItemIcon>}

          <EllipsisText>{label}</EllipsisText>
        </MenuItem>
      </Box>
    </Tooltip>
  );
};

interface CheckboxButtonProps {
  checked: boolean;
  checkboxColor?: string;
}

const CheckboxButton = ({ checked, checkboxColor }: CheckboxButtonProps) => {
  const sx = useMemo<SxProps | undefined>(
    () => ({
      ...iconStyles,
      '& .MuiSvgIcon-root': {
        fill: checkboxColor,
      },
    }),
    [checkboxColor],
  );

  return <Checkbox checked={checked} sx={sx} />;
};
