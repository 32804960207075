import axios from 'axios';
import { v4 } from 'uuid';
import { cloneDeep } from 'lodash';
import {
  IElement,
  IElementID,
  Project,
} from '../../../shared/models/project.interface';
import { ExpressionVariables } from '../../../shared/helpers/expression_variables_helpers';
import {
  flattenElements,
  forEachElement,
  isProductElement,
  isRecipeElement,
} from '../../../shared/helpers/recursive_element_helpers';
import {
  Recipe,
  RecipeID,
  RecipeRecord,
} from '../../../shared/models/recipe.interface';
import {
  Product,
  ProductRecord,
} from '../../../shared/models/product.interface';
import { DateTime } from 'luxon';
import {
  validateProject,
  validateRecipeProducts,
} from '../../../shared/validation';
import { getRecipesUsedInProject } from '../../../shared/helpers/recipe_helpers';
import { IConfig } from '../providers/ConfigProvider';
import { createRecordByKey } from '../../../shared/helpers/array_helpers';
import { ArrayOrRecord } from '../../../shared/models/type_helpers.interface';
import { mapFilterRecord } from '../../../shared/helpers/object_helpers';
import { updateElements } from '../../../shared/helpers/project_helpers';
import {
  getElementProperties,
  isElementPropertySourceRecipe,
} from '../../../shared/helpers/element_property_helpers';
import { IElementProperty } from '../../../shared/models/element_property.interface';
import { isNameMatch } from '../../../shared/helpers/string_helpers';
import { createProduct } from '../../../shared/helpers/product-factory.helpers';

export const getFileContents = async (
  file: File,
): Promise<string | ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target ? (e.target.result as string) : '');
    };
    reader.onerror = (e) => {
      reject(e);
    };
    /* bidcon files are latin1. yuck! */

    if (
      file.type.includes('xlsx') ||
      file.type.includes('officedocument.spreadsheet')
    ) {
      reader.readAsArrayBuffer(file);
    } else {
      reader.readAsText(file, file.type === 'text/xml' ? 'latin1' : 'utf8');
    }
  });
};

export const getJSONFromFile = async <T>(file: File): Promise<T> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        resolve(JSON.parse(e.target ? (e.target.result as string) : '{}'));
      } catch (error: any) {
        reject(new Error(error));
      }
    };
    reader.onerror = (e) => {
      reject(e);
    };

    reader.readAsText(file, 'utf8');
  });
};

export const getElementGroupsFromFiles = async (
  files: File[],
  variables?: ExpressionVariables,
): Promise<IElement[]> => {
  const contents = await Promise.all(files.map(getFileContents));

  const getElementGroups = async (
    c: string | ArrayBuffer,
    i: number,
  ): Promise<IElement[]> => {
    const file = files[i];

    if (!file) return [];

    if (file.type.includes('officedocument.spreadsheet')) {
      const formData = new FormData();
      formData.append('xlsx', new Blob([c as ArrayBuffer]));
      formData.append(
        'json_data',
        JSON.stringify({ filename: file?.name, variables }),
      );
      const response = await axios.post('/files/xlsx', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      return response.data as IElement[];
    } else if (file.type.includes('xml')) {
      const response = await axios.post('/files/bidcon', {
        filename: file?.name,
        string: c as string,
      });
      return response.data as IElement[];
    } else {
      const response = await axios.post('/files/revit', {
        filename: file.name,
        string: c as string,
      });
      return response.data as IElement[];
    }
  };

  return (
    await Promise.all(
      contents.map(async (c, i) => await getElementGroups(c, i)),
    )
  ).flat();
};

export const getProductsToAdd = (
  importedProductRecord: ProductRecord,
  correspondingProducts: ProductRecord,
  organization: string,
  owner: string,
): ProductRecord =>
  mapFilterRecord(importedProductRecord, (product) => {
    // If the product is not already available in the target organization we need to create it
    if (!correspondingProducts[product.id]) {
      return createProduct({
        ...product,
        owner,
        organizations: [organization],
        source: 'custom',
        id: undefined,
      });
    }
  });

/**
 * Get a product from the target organization that matches the product.
 * If no match is found by id, try to match by name.
 * @param product
 * @param existingProducts
 * @returns
 */
const getCorrespondingProduct = (
  product: Product,
  existingProducts: ProductRecord,
): Product | undefined => {
  // First try to match by id
  if (existingProducts[product.id]) {
    return existingProducts[product.id];
  }

  // If not try to match by name
  return Object.values(existingProducts).find(
    (p) => p.source === product.source && isNameMatch(p.name, product.name),
  );
};

/**
 * Get all products that already exist in the target organization.
 * Primarily by id (if imported to same org or if it's Nodon product) else by name.
 * @param newProducts Products to import
 * @param existingProducts Products on the target organization
 * @returns ProductRecord<OldId, NewProduct>
 */
export const getCorrespondingProductRecord = (
  newProducts: ArrayOrRecord<Product>,
  existingProducts: ProductRecord,
): ProductRecord =>
  createRecordByKey(newProducts, 'id', (product) =>
    getCorrespondingProduct(product, existingProducts),
  );

/**
 * Get a recipe from the target organization that matches the recipe.
 * If no match is found by id, try to match by name.
 * @param recipe
 * @param availableRecipes
 * @returns
 */
const getCorrespondingRecipe = (
  recipe: Recipe,
  assignableRecipeRecord: RecipeRecord,
): Recipe | undefined => {
  // First try to match by id
  if (assignableRecipeRecord[recipe.id]) {
    return assignableRecipeRecord[recipe.id];
  }

  // If not try to match by name & category
  return Object.values(assignableRecipeRecord).find(
    (assignableRecipe) =>
      assignableRecipe.category_id === recipe.category_id &&
      isNameMatch(assignableRecipe.name, recipe.name),
  );
};

/**
 * Get all recipes that already exist in the target organization.
 * Primarily by id (if imported to same org), else by name.
 * @param recipes
 * @param assignableRecipeRecord
 * @returns
 */
export const getCorrespondingRecipeRecord = (
  recipes: ArrayOrRecord<Recipe>,
  assignableRecipeRecord: RecipeRecord,
): RecipeRecord =>
  createRecordByKey(recipes, 'id', (recipe) =>
    getCorrespondingRecipe(recipe, assignableRecipeRecord),
  );

/**
 * Get recipes with new ids and products correctly mapped
 * @param importedRecipeRecord
 * @param correspondingRecipes
 * @param owner
 * @param organization
 * @returns
 */
export const getRecipesToAdd = (
  importedRecipeRecord: RecipeRecord,
  correspondingRecipes: RecipeRecord,
  correspondingProducts: ProductRecord,
  owner: string,
  organization: string,
): RecipeRecord =>
  mapFilterRecord(importedRecipeRecord, (recipe) => {
    // If the recipe is not already available in the target organization we need to create it
    if (!correspondingRecipes[recipe.id]) {
      // Make sure changes to the recipe does not affect the original recipe
      recipe = {
        ...recipe,
        owner,
        id: v4(),
        organizations: [organization],
        elements: cloneDeep(recipe.elements),
      };

      // Make sure we map the old product id to the new product id
      flattenElements(...recipe.elements).forEach((ingredient) => {
        const product_id =
          isProductElement(ingredient) && ingredient.product_id;

        if (product_id) {
          const newId = correspondingProducts[product_id]?.id;

          if (!newId) {
            throw new Error(
              `Could not find corresponding product for ${product_id}, product must be missing in import`,
            );
          }

          // If the ingredient is a product, we need to map the old id to the new id
          ingredient.product_id = newId;
        }
      });
      return recipe;
    }
  });

export const addRecipes = async (
  recipesToAdd: RecipeRecord,
  organization: string,
  createRecipe: (recipe: Recipe, organization: string) => Promise<Recipe>,
): Promise<RecipeRecord> => {
  // We need to keep track of the old id so we can map it to the new id
  const promisesWithId: [RecipeID, Promise<Recipe>][] = Object.entries(
    recipesToAdd,
  ).map(([id, recipe]) => [id, createRecipe(recipe, organization)]);

  // Wait for all promises to resolve
  const addedRecipes = await Promise.all(
    promisesWithId.map(([, promise]) => promise),
  );

  // Remap the created recipes to a record with the old id as key
  return addedRecipes.reduce((acc, recipe, index) => {
    const [id] = promisesWithId?.[index] ?? [];

    if (id) {
      return { ...acc, [id]: recipe };
    }
    return acc;
  }, {} as RecipeRecord);
};

export const replaceProjectRecipeIds = (
  project: Project,
  recipeRecord: RecipeRecord,
): Project => {
  const changes: {
    recipe_id: RecipeID;
    properties: IElementProperty[];
    id: IElementID;
  }[] = [];

  forEachElement(project, (element) => {
    const oldId = isRecipeElement(element) && element.recipe_id;
    const newRecipe = oldId && recipeRecord[oldId];

    if (newRecipe) {
      const recipe_id = newRecipe.id;
      const properties = getElementProperties(element).map((property) =>
        isElementPropertySourceRecipe(property)
          ? {
              ...property,
              recipe_id,
            }
          : property,
      );
      changes.push({
        recipe_id,
        properties,
        id: element.id,
      });
    }
  });

  return updateElements(project, ...changes);
};

export interface ExportObject {
  project: Project;
  recipes: Recipe[];
  // These has not been around for long, so they might not be present. Should probably be required in the future
  version?: string;
  environment?: string;
}

const downloadJSON = (data: any, filename: string): void => {
  const json = JSON.stringify(data);
  const link = document.createElement('a');

  link.href = `data:text/json;chatset=utf-8,${encodeURIComponent(json)}`;
  link.download = filename;
  link.click();
};

export const printRecord = (
  msg: string,
  itemRecord: ArrayOrRecord<{ name: string; id: string }>,
): void => {
  const array = Object.values(itemRecord);
  console.info(
    `${msg} (${array.length}):`,
    array.map((item) => `${item.name} (${item.id})`),
  );
};

export const exportProject = (
  project: Project,
  recipes: Recipe[],
  config: IConfig,
): void => {
  // Only export recipes that are used in the project
  const filteredRecipes = getRecipesUsedInProject(project, recipes);

  // Make sure we don't export recipes with missing products
  validateRecipeProducts(project, filteredRecipes);

  const exportObject: ExportObject = {
    project: validateProject(project), // Crash if project is invalid
    recipes: filteredRecipes,
    environment: config.sentryEnvironment,
    version: config.version,
  };

  const timestamp = DateTime.now().toFormat('yyyy-MM-dd HH:mm');
  downloadJSON(exportObject, `${project.name} ${timestamp}.json`);
};
