import React, { useMemo } from 'react';
import { DeleteForever, Edit } from '@mui/icons-material';
import { projectActionMenuIconStyles } from '../../../style/constants';
import { IProposal } from '../../../../../shared/models/proposals.interface';
import { useDeleteProposal, useProposals } from '../../../hooks/proposals.hook';
import { KebabButtonMenu } from '../../SelectMenu/KebabButtonMenu';

interface IProposalKebabMenuInput {
  proposal: IProposal;
  onEditName: () => void;
  onClose?: () => void;
}

const ProposalKebabMenu: React.FC<IProposalKebabMenuInput> = ({
  proposal: proposal,
  onEditName,
  onClose,
}) => {
  const { id } = proposal;
  const deleteProposal = useDeleteProposal();
  const proposals = useProposals();
  const disableDelete = proposals.length < 2;

  const items = useMemo(
    () => [
      {
        id: 'rename',
        label: 'Edit name',
        icon: <Edit {...projectActionMenuIconStyles} />,
        onClick: onEditName,
      },
      {
        id: 'delete',
        label: 'Delete proposal',
        icon: <DeleteForever {...projectActionMenuIconStyles} />,
        onClick: () => deleteProposal(id),
        disabled: disableDelete,
      },
    ],
    [deleteProposal, disableDelete, id, onEditName],
  );

  return <KebabButtonMenu id={id} items={items} onClose={onClose} />;
};

export default ProposalKebabMenu;
