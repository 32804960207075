import { Box, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import NodonSelect from '../../../NodonSelect';
import { useElementResults } from '../../../../hooks/results.hook';
import { formatThousands } from '../../../../../../shared/helpers/math_helpers';
import {
  Results,
  ConversionFactors,
  EmissionCostLabel,
  SelectableQuantityUnit,
} from '../../../../../../shared/models/unit.interface';
import { useProjectBuildingGFA } from '../../../../store/project';
import { NodonTheme } from '../../../../style';
import {
  convertConversionFactors,
  convertToThousandSEK,
} from '../../../../../../shared/helpers/conversion_helpers';
import { isSelectableQuantityUnit } from '../../../../../../shared/helpers/unit_helpers';
import { uniq } from 'lodash';
import { createLocalStorageStore } from '../../../../helpers/local-storage.helpers';

type CostEmissionFactor = keyof Pick<
  ConversionFactors,
  'co2e_total' | 'sek_A1-A3'
>;
type FactorUnit = Exclude<SelectableQuantityUnit, 'l' | 'mm'> | 'total' | 'GFA';
type FactorUnitRecord = Record<CostEmissionFactor, FactorUnit[]>;

const defaultFactorUnits: FactorUnitRecord = {
  co2e_total: ['total'],
  'sek_A1-A3': ['total'],
};

// Todo use record store
const { useStore: useConversionFactorState, set: setPreferredFactorUnits } =
  createLocalStorageStore<FactorUnitRecord>(
    'preferred_product_element_emission_unit',
    defaultFactorUnits,
  );

interface EmissionsProps {
  elementId: string;
  factor: CostEmissionFactor;
}

const ResultPanel: React.FC<EmissionsProps> = ({ elementId, factor }) => {
  const gfa = useProjectBuildingGFA();
  const results = useElementResults(elementId);

  const selectableUnits: FactorUnit[] = useMemo(() => {
    return ['total', 'GFA', ...Object.keys(results).filter(isFactorUnit)];
  }, [results]);

  const preferredFactorUnits = useConversionFactorState();

  const selectedUnit = useMemo(() => {
    const factorUnits = preferredFactorUnits?.[factor]
      ? preferredFactorUnits[factor]
      : defaultFactorUnits[factor];

    return (
      factorUnits.find((unit) => selectableUnits.includes(unit)) ?? 'total'
    );
  }, [factor, preferredFactorUnits, selectableUnits]);

  const selectedFactorValue = useMemo(() => {
    return getFactorValue(factor, selectedUnit, results, gfa);
  }, [results, gfa, selectedUnit, factor]);

  const handleChange = useCallback(
    (unit: string) => {
      if (!isFactorUnit(unit)) {
        throw new Error(`Invalid unit: ${unit}`);
      }

      const factorUnits = preferredFactorUnits?.[factor]
        ? preferredFactorUnits
        : defaultFactorUnits;

      setPreferredFactorUnits({
        ...factorUnits,
        [factor]: uniq([unit, ...factorUnits[factor]]),
      });
    },
    [factor, preferredFactorUnits],
  );

  const options = useMemo(
    () => selectableUnits.map((u) => ({ value: u, label: getUnitPrefix(u) })),
    [selectableUnits],
  );

  return (
    <Box
      pt={1}
      pb={2}
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <NodonSelect
        buttonLabel={getButtonLabel(factor, selectedUnit)}
        options={options}
        onChange={handleChange}
        buttonStyles={{ color: NodonTheme.palette.text.primary }}
      />
      <Typography variant="body2">
        {selectedFactorValue} {getUnitLabel(factor)}
      </Typography>
    </Box>
  );
};

const isFactorUnit = (unit: unknown): unit is FactorUnit =>
  typeof unit === 'string' &&
  !['mm', 'l'].includes(unit) && // Don't show these units
  (isSelectableQuantityUnit(unit) || ['total', 'GFA'].includes(unit)); // Selectable units + total and GFA

const getUnitPrefix = (unit: FactorUnit) => {
  if (unit === 'total') {
    return 'Total';
  }
  return `Per ${unit}`;
};

const getUnitLabel = (
  factor: CostEmissionFactor,
): EmissionCostLabel | undefined => {
  if (factor === 'co2e_total') return 'kgCO2e';
  if (factor === 'sek_A1-A3') return 'kSEK';
};

const getButtonLabel = (factor: CostEmissionFactor, unit: FactorUnit) => {
  let factorLabel;
  if (factor === 'co2e_total') factorLabel = 'Emissions';
  if (factor === 'sek_A1-A3') factorLabel = 'Cost';

  return `${factorLabel ?? 'unkown factor'} ${getUnitPrefix(unit)}`;
};

const getFactorValue = (
  factor: CostEmissionFactor,
  unit: FactorUnit,
  conversionFactors: Results,
  gfa: number,
) => {
  let res = 0;

  if (unit === 'total') {
    res = conversionFactors[factor] ?? 0;
  } else if (unit === 'GFA') {
    res = conversionFactors[factor] ?? 0;
    res = res < 1 ? res : res / gfa;
  } else {
    res = convertConversionFactors(conversionFactors, unit)[factor] ?? 0;
  }

  return formatThousands(convertToThousandSEK(res, getUnitLabel(factor)));
};

export default ResultPanel;
