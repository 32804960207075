import React, { memo, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  IconButton,
  OutlinedTextFieldProps,
  Tooltip,
} from '@mui/material';
import { useIsReadonly } from '../../../hooks/user.hook';
import { Row } from '../Row';
import { RowCell } from '../RowCell';
import {
  ROOT_CELL_WIDTH,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  ELEMENT_LIST_ITEM_HEIGHT,
  ELEMENT_COST_BAR_HEIGHT,
  ROOT_CELL_WIDTH_RESPONSIVE,
} from '../list.constants';
import { useBooleanState } from '../../../hooks/hooks';
import { useTriggerContextMenu } from '../../../hooks/menu.hooks';
import { useListRowStyles } from '../list.style';
import { useMouseEventCallback } from '../../../hooks/events.hook';
import InlineTextField from '../../InlineTextField';
import { useRemoveElements, useUpdateElements } from '../../../store/project';
import SimpleBarChart from '../../charts/SimpleBarChart';
import { makeStyles } from 'tss-react/mui';
import { formatThousands } from '../../../../../shared/helpers/math_helpers';
import { IBuildingVersion } from '../../../../../shared/models/project.interface';
import { useNavigateTo } from '../../../hooks/router.hooks';
import { Check, Delete, Edit } from '@mui/icons-material';
import { NodonTheme } from '../../../style';
import { useSelectedVersionId } from '../../../store/ui';
import {
  useProjectVersionsMax,
  useVersionResultRecord,
} from '../../../hooks/results.hook';
import {
  getActiveProposal,
  useGetProposalColor,
} from '../../../hooks/proposals.hook';
import MultiLineTooltip from '../../MultiLineTooltip';
import { useValuePerGFA } from '../../../hooks/useCO2eCost';
import { Results } from '../../../../../shared/models/unit.interface';
import { useVersions } from '../../../hooks/useElement';

interface VersionListItemProps {
  version: IBuildingVersion;
  autoFocus?: boolean;
  closeMenu: () => void;
}

const VersionListItem: React.FC<VersionListItemProps> = ({
  version,
  autoFocus,
  closeMenu,
}) => {
  const { id } = version;
  const { classes: listClasses } = useListRowStyles();
  const { classes, cx } = useStyles();

  const updateElements = useUpdateElements();
  const triggerContextMenu = useTriggerContextMenu(id);
  const navigate = useNavigateTo();
  const removeVersion = useRemoveElements({ showConfirm: true });
  const getProposalColor = useGetProposalColor();
  const valuePerGFA = useValuePerGFA();

  const readonly = useIsReadonly();
  const selectedVersionId = useSelectedVersionId();
  const versions = useVersions();

  const results = useVersionResultRecord(version)[version.id];
  const co2ePerGfaMax = valuePerGFA(useProjectVersionsMax('co2e_total'));
  const costPerGfaMax = valuePerGFA(useProjectVersionsMax('sek_A1-A3'));

  const { co2e_total: co2ePerGFA, 'sek_A1-A3': costPerGFA } = valuePerGFA(
    results ?? ({ co2e_total: 0, 'sek_A1-A3': 0 } as Results),
  );

  const [hover, startHover, endHover] = useBooleanState();

  const [isEditingName, startEditingName, stopEditingName] =
    useBooleanState(false);

  const iconTooltip = useMemo(() => {
    if (isEditingName) {
      return versions.length > 1 ? 'Delete' : '';
    }
    return 'Edit';
  }, [isEditingName, versions.length]);

  const proposalColor = useMemo(() => {
    const activeProposal = getActiveProposal();

    if (activeProposal && activeProposal.resultsRecord?.[version.id]) {
      return getProposalColor(activeProposal);
    }
  }, [getProposalColor, version.id]);

  const saveName = useCallback(
    async (name: string) => {
      await updateElements({ id: version.id, name });
      stopEditingName();
    },
    [stopEditingName, updateElements, version],
  );

  const editName = useCallback(() => {
    startEditingName();
    endHover();
  }, [endHover, startEditingName]);

  const handleIconClick = useMouseEventCallback(() => {
    if (!isEditingName) {
      editName();
      return;
    }
    removeVersion(version.id);
  });

  const handleNameInputClick = useMouseEventCallback(() => {
    startEditingName();
  });

  const handleRowClick = useMouseEventCallback(() => {
    if (!isEditingName) {
      navigate({ versionId: version.id });
      closeMenu();
    }
  });

  const handleCancelEdit = useCallback(() => {
    setTimeout(() => stopEditingName(), 250);
  }, [stopEditingName]);

  // Start editing the name if autoFocus is set
  useEffect(() => {
    autoFocus && startEditingName();
  }, [autoFocus, startEditingName]);

  const nameInputProps: Partial<OutlinedTextFieldProps> = useMemo(
    () => ({
      autoFocus,
      onFocus: (e) => {
        e.currentTarget.select();
      },
    }),
    [autoFocus],
  );

  return (
    <Box
      width="100%"
      component="div"
      onMouseLeave={endHover}
      onMouseOver={startHover}
      onClick={handleRowClick}
    >
      <Row
        classes={listClasses}
        height={ELEMENT_LIST_ITEM_HEIGHT}
        onContextMenu={triggerContextMenu}
        hover={hover}
        padding={true}
        spacing={LIST_SPACING}
      >
        {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT * 0.8}
          paddingRight={LIST_SPACING}
        >
          <Row height={ELEMENT_LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
            {/* Check icon */}
            <RowCell width={ROOT_CELL_WIDTH.ICON} align="center">
              {selectedVersionId === version.id && (
                <Check fontSize="small" className={classes.checkIcon} />
              )}
            </RowCell>

            {/* Name input */}
            <RowCell
              width={CONTENT_CELL_WIDTH.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
              paddingLeft={10}
            >
              <InlineTextField
                autoWidth
                variant="subtitle1"
                value={version.name}
                editing={isEditingName}
                disabled={readonly || !isEditingName}
                textFieldProps={nameInputProps}
                onClick={handleNameInputClick}
                onSave={saveName}
                onCancel={handleCancelEdit}
              />
            </RowCell>

            {/* Kebab menu */}
            <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
              {!readonly && hover && (
                <Tooltip title={iconTooltip} placement="right">
                  <Box>
                    <IconButton
                      size="small"
                      onClick={handleIconClick}
                      disabled={isEditingName && versions.length <= 1}
                    >
                      {isEditingName ? (
                        <Delete fontSize="small" />
                      ) : (
                        <Edit fontSize="small" />
                      )}
                    </IconButton>
                  </Box>
                </Tooltip>
              )}
            </RowCell>
          </Row>
        </RowCell>

        {/* Bar chart */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <Box className={cx(classes.chartContainer)}>
            {/* CO2 Bar */}
            <Box className={cx(classes.chart)}>
              <SimpleBarChart
                color={proposalColor}
                value={co2ePerGFA}
                maxValue={co2ePerGfaMax}
              />
            </Box>

            {/* Cost Bar */}
            <Box className={cx(classes.chart)}>
              <SimpleBarChart
                color={'black'}
                height={ELEMENT_COST_BAR_HEIGHT}
                value={costPerGFA}
                maxValue={costPerGfaMax}
              />
            </Box>
          </Box>
        </RowCell>

        {/* CO2 Label */}
        <RowCell
          width={CONTENT_CELL_WIDTH.LABEL}
          align="center"
          fontSize={12}
          fontWeight={700}
        >
          <MultiLineTooltip
            rows={['Emissions per GFA', 'kgCO2e / m2']}
            disableInteractive
          >
            {formatThousands(co2ePerGFA)}
          </MultiLineTooltip>
        </RowCell>

        {/* Cost Label */}
        <RowCell
          width={CONTENT_CELL_WIDTH.LABEL}
          align="center"
          fontSize={10}
          fontWeight={400}
        >
          <MultiLineTooltip
            rows={['Cost per GFA', 'kSEK / m2']}
            disableInteractive
          >
            {formatThousands(costPerGFA ?? 0)}k
          </MultiLineTooltip>
        </RowCell>
      </Row>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  container: {
    position: 'relative',
    display: 'block',
    height: 'auto',
  },
  dimmed: {
    opacity: 0.5,
  },
  chartContainer: {
    display: 'block',
    width: '100%',
  },
  chart: {
    '&:not(:last-child)': {
      marginBottom: '3px',
    },
  },
  checkIcon: {
    color: NodonTheme.palette.neutral.light,
  },
}));

export default memo(VersionListItem);
